import { FormEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { getStorageData } from 'framework/src/Utilities';
import { conditionSort } from '../../../components/src/Helper';

export const webConfigJSON = require("./config.js");
export const configJSON = require("./config");
const flattenObject = (obj: any, formData: FormData, parentKey: string = ""): void => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const keyName = parentKey ? `${parentKey}[${key}]` : key;

      if (obj[key] instanceof File) {
        formData.append(keyName, obj[key], obj[key].name);
      } else if (obj[key] === null || obj[key] === undefined) {
        formData.append(keyName, "");
      } else if (typeof obj[key] === "object") {
        flattenObject(obj[key], formData, keyName);
      } else {
        formData.append(keyName, obj[key]);
      }
    }
  }
};



export interface Props {
  navigation: any;
  classes: Record<string, string>;
  sellerType?: boolean;
}
interface S {
  isEditMode: boolean;
  getDocumentURL: string;
  selectedDocumentFile: File | null;
  selectedAvatar: File | null;
  getAvatarURL: string | null;
  selectedCountry: string;
  selectedCity: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  linkedinLink: string;
  aboutYourself: string;
  numberAcquisitionClosed: string;
  numberProjectedAnnualAquisitions: string;
  gender: string;
  role: string;
  role_id:string;
  accreditedBuyer: string;
  preferred_lang: any;
  userID: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  IsValidURL: boolean;
  InValidAge: string;
  IsValidUrlMess: string;
  FirstNameError: string;
  LastNameError: string;
  AboutYourselfError: string;
  NumberAcquisitionError: string;
  ProjectedAquisitionsError: string;
  userType: string;
  companyType: string;
  language: string;
  countriesData: CountriesStateInterface[];
  buyerId: string;
  profileUserRoles:[{
    id:string;
    title: string;
     value:string;

  }];
  pageText:{
    editProfile: string;
    fullProfile: string;
    fullProfileInfo: string;
  profilePicture: string;
  uploadPhoto: string;
  firstName: string;
  lastName: string;
  gender: string;
  dob: string;
  selectRole: string;
  linkedIn: string;
  selectCountry: string;
  selectCity: string;
  aboutYourself: string;
  noOf: string;
  pro: string;
  ver: string;
  upl: string;
  acc: string;
  save: string;
  clear: string;
  preferredLang: string;
  down: string;
  success: string;
  backBtn: string;
  backBtnB: string;
  min: string;
pefn: string;
twenty: string;
max: string;
two: string;
lName: string;
lUrl: string;
  }
}
interface SS { }
export interface CountryData {
  icon: any;
  country: string;
  cities: string[];
}

export interface CountriesInterface {
  name: string,
  arabicName: string,
  icon: string,
  cities: CityInterface[]
}

export interface CityInterface {
  name: string, arabicName: string
}
export interface CountriesStateInterface {
  title: string,
  value: string,
  icon: string
  cities: CityStateInterface[]
}

export interface CityStateInterface {
  title: string, value: string
}
export default class BuyerProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  putApiBuyerProfileCallId: string = "";
  getApiBuyerProfileTokenCallId: string = "";
  getApiBuyerProfileCallId: string = "";
  getUserProfileRolesAPICallId:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      isEditMode: false,
      getDocumentURL: '',
      countriesData: [],
      selectedDocumentFile: null,
      selectedAvatar: null,
      getAvatarURL: '',
      selectedCountry: '',
      selectedCity: '',
      firstName: '',
      userType: '',
      lastName: '',
      dateOfBirth: 'dd/mm/yyyy',
      linkedinLink: '',
      aboutYourself: '',
      numberAcquisitionClosed: '',
      numberProjectedAnnualAquisitions: '',
      gender: '',
      role: '',
      role_id:"",
      accreditedBuyer: '',
      preferred_lang: '',
      userID: '',
      errorMsg: "",
      loading: false,
      token: "",
      IsValidURL: false,
      InValidAge: "",
      IsValidUrlMess: "",
      FirstNameError: "",
      LastNameError: "",
      AboutYourselfError: "",
      NumberAcquisitionError: "",
      ProjectedAquisitionsError: "",
      companyType: '',
      buyerId: '',
      language: "",
      profileUserRoles:[{
        id:"",
        title: "",
         value:""
    
      }],
      pageText:{
        backBtn: '',
        backBtnB: '',
        fullProfile: '',
        editProfile: '',
        fullProfileInfo: '',
      profilePicture: '',
      uploadPhoto: '',
      firstName: '',
      lastName: '',
      gender: '',
      dob: '',
      selectRole: '',
      linkedIn: '',
      selectCountry: '',
      selectCity: '',
      aboutYourself: '',
      noOf: '',
      pro: '',
      ver: '',
      upl: '',
      acc: '',
      save: '',
      clear: '',
      preferredLang: '',
      success: '',
      down: '',
      min: '',
pefn: '',
twenty: '',
max: '',
two: '',
lName: '',
lUrl: ''
      }
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Get token from local storage
    const token = localStorage.getItem("token") || "";
    this.receiveTokenValue(token);
    this.getCompanyType();
  }

  handleEditBtn = () => this.setState({isEditMode: true});

  translateText = () => {
    let pageText = this.state.language == 'ar' ? configJSON.userProfileAr: configJSON.userProfileEn;
    this.setState({pageText: pageText})
  }

  receiveTokenValue = async (token: string) => {
    let curLang = await getStorageData("language") || 'en';
    let userType = await getStorageData("typeOfUser");
    this.setState({ token: token, language: curLang, userType: userType }, () => {
      if (!token) {
        return this.navigateToRoute("EmailAccountLoginBlock");
      } else {
        this.translateText();
        let countries: CountriesStateInterface[] = [];
        configJSON.realCountries.forEach((element: CountriesInterface) => {
          let cities: CityStateInterface[] = [];
          element.cities.forEach((cityObj: CityInterface) => {
            cities.push({title: conditionSort(this.state.language == 'en', cityObj.name, cityObj.arabicName), value: cityObj.name})
          })
          countries.push({title: conditionSort(this.state.language == 'en', element.name, element.arabicName), value: element.name, icon: element.icon , cities: [...cities]})
        });
        
        let buyerId: string = this.props.navigation.getParam("id");
        this.setState({
          countriesData: countries,
          buyerId: buyerId
        }, ()=> {
          if(buyerId) {
            this.getBuyerProfileByToken();
          } else {
            this.getBuyerProfile()
          }
        });

        
        this.getUserProfileRoles();
        
      }
    });
  };

  getCompanyType = async () => {
    let type = await getStorageData('companyType')
    this.setState({ companyType: type })
  }
  getUserProfileRoles = async () => {
    const header = {
      token: await getStorageData('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileRolesAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.getUserProfileRoleEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleGetProfileRolesResponse(responseJson: any) {

    const roleOptionsdata =  responseJson.data.map((item:{id: string, attributes: {user_roles: string, arabic_user_roles: string}})=>{
      const userRole = conditionSort(this.state.language == 'ar', item.attributes.arabic_user_roles, item.attributes.user_roles)
      return {
      id:item.id, title: userRole, value: userRole
      }
    });
 
    this.setState({
      profileUserRoles:roleOptionsdata
    });
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (responseJson && !responseJson.errors) {
      this.handleSuccessfulResponse(apiRequestCallId, responseJson);
    } else if (responseJson && responseJson.errors) {
      this.handleErrorResponse(responseJson.errors[0]["token"]);
      this.navigateToRoute("EmailAccountLoginBlock")
    } else if (errorReponse) {
      this.handleErrorResponse(errorReponse);
    }
  }

  handleSuccessfulResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.putApiBuyerProfileCallId) {
      this.handlePutApiBuyerProfileResponse(responseJson);
    } else if ((apiRequestCallId === this.getApiBuyerProfileCallId || apiRequestCallId == this.getApiBuyerProfileTokenCallId) && responseJson?.data) {
      this.handleGetApiBuyerProfileResponse(responseJson);
    } else if (apiRequestCallId === this.getUserProfileRolesAPICallId) {
      this.handleGetProfileRolesResponse(responseJson);
    }
  }

  handlePutApiBuyerProfileResponse(responseJson: any) {
    this.setState({
      loading: false,
      errorMsg: '',
      isEditMode: false
    });
    toast.success(this.state.pageText.success);
    this.getBuyerProfile();
  }

  handleGetApiBuyerProfileResponse = async (responseJson: any) => {
    const {
      first_name, last_name, date_of_birth, gender, bio,
      social_media_link, user_type, number_of_acquisition_closed, projected_annual_acquisitions,
      accredited_buyer, country, city, image, document, preffered_language,role
    } = responseJson.data.attributes;

    let user_role = "";
      user_role = role ? conditionSort(this.state.language == 'ar', role.arabic_user_roles, role.user_roles) : "";

    this.setState({
      firstName: first_name, lastName: last_name, dateOfBirth: date_of_birth, gender: gender, aboutYourself: bio,
      linkedinLink: social_media_link, numberAcquisitionClosed: number_of_acquisition_closed,
      numberProjectedAnnualAquisitions: projected_annual_acquisitions, accreditedBuyer: accredited_buyer,
      selectedCountry: country, selectedCity: city,
      userID: responseJson.data.id,
      preferred_lang: preffered_language,
      role: user_role
    });

    if (image) {
      this.setState({
        getAvatarURL: image.url,
      });
    }
    if (document) {
      this.setState({
        getDocumentURL: document.url,
      });
    }
  }

  handleErrorResponse(errorReponse: any) {
    this.setState({
      loading: false,
      errorMsg: errorReponse,
    });
  }


  editBuyerProfile = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const {
      firstName,
      lastName,
      gender,
      dateOfBirth,
      linkedinLink,
      aboutYourself,
      numberAcquisitionClosed,
      numberProjectedAnnualAquisitions,
      selectedDocumentFile,
      selectedAvatar,
      selectedCountry,
      selectedCity,
      role_id,
      accreditedBuyer,
      preferred_lang
    } = this.state;
    // Prepare header object
    const header = {
      token: this.state.token,
    };

    // Prepare data
    const data: any = {
      data: {
        attributes: {
          first_name: firstName,
          last_name: lastName,
          date_of_birth:  dateOfBirth.replace(/-/g, "/"),
          bio: aboutYourself,
          social_media_link: linkedinLink,
          gender: gender,
          country: selectedCountry,
          city: selectedCity,
          number_of_acquisition_closed: numberAcquisitionClosed,
          projected_annual_acquisitions: numberProjectedAnnualAquisitions,
          accredited_buyer: accreditedBuyer,
          role_id:role_id,
          preffered_language: preferred_lang,
          profile_percent: `${selectedAvatar === null && this.state.getAvatarURL == '' ? 92 : 100}`
        },
      },
    };
    if (selectedAvatar) {
      data.data.attributes.image = selectedAvatar;
    }
    if (selectedDocumentFile) {
      data.data.attributes.document = selectedDocumentFile;
    }
    const formData = new FormData();

    flattenObject(data, formData);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putApiBuyerProfileCallId = requestMessage.messageId;

    // Set endpoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/user_type"
    );

    // Set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // Set HTTP method
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    // Set body
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );

    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBuyerProfile() {
    // Prepare header object
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getApiBuyerProfileCallId = requestMessage.messageId;

    // Set endpoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/start_ups/buyer_profile?lang=${this.state.language}`
    );

    // Set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // Set HTTP method
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBuyerProfileByToken() {
    // Prepare header object
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getApiBuyerProfileTokenCallId = requestMessage.messageId;

    // Set endpoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/start_ups/buyer_profile_with_id?id=${this.state.buyerId}&&locale=${this.state.language}`
    );

    // Set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // Set HTTP method
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  clearFrom() {
    this.setState({
      selectedAvatar: null,
      selectedDocumentFile: null,
      selectedCountry: '',
      selectedCity: '',
      dateOfBirth: '',
      linkedinLink: '',
      aboutYourself: '',
      numberAcquisitionClosed: '',
      numberProjectedAnnualAquisitions: '',
      gender: '',
       role: '', 
      accreditedBuyer: '',
      preferred_lang: ''
    });
  }

  isAllClear() {
    const {
      selectedAvatar,
      selectedDocumentFile,
      selectedCountry,
      selectedCity,
      firstName,
      lastName,
      dateOfBirth,
      linkedinLink,
      aboutYourself,
      numberAcquisitionClosed,
      numberProjectedAnnualAquisitions,
      gender,
      accreditedBuyer,
    } = this.state;

    // Check if all states are empty
    return (
      !selectedAvatar &&
      !selectedDocumentFile &&
      !selectedCountry &&
      !selectedCity &&
      !firstName &&
      !lastName &&
      !dateOfBirth &&
      !linkedinLink &&
      !aboutYourself &&
      !numberAcquisitionClosed &&
      !numberProjectedAnnualAquisitions &&
      !gender &&
      !accreditedBuyer
    );
  }

  handleAccordionChange = (stateName: string, selectedValue: string) => {
    if (stateName === "selectedCountry") {
      // Reset selectedCity when country changes
      this.setState({
        selectedCountry: selectedValue,
        selectedCity: '', // Reset selectedCity
      });
    } else if (stateName === "role") {
      this.state.profileUserRoles.map((item)=>{
        if(item.title===selectedValue){
          this.setState({
            role_id: item.id,
          });
        }
      })
      this.setState({
        role: selectedValue,
      });
    }  else {
      this.setState({
        [stateName]: selectedValue
      } as unknown as Pick<S, keyof S>);
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>) => {
    const { name, value } = 'target' in event ? event.target : (event as { name?: string; value: unknown });
    const firstNameRegex =  /^[a-zA-Z\u0621-\u064A\u0660-\u0669\s]+$/;

    if ((name !== "firstName" && name !== "lastName") || firstNameRegex.test(value as string) || value === '') {
      this.setState({
        [name as string]: value,
      } as unknown as Pick<S, keyof S>);
    }
    if (name === "firstName") {
      this.handleFirstNameError(value as string)
    }
    if (name === "aboutYourself") {
      this.handleAboutYourSelfError(value as string)
    }
    if (name === "lastName") {
      this.handleLastNameError(value as string)
    }
    if (name === "linkedinLink") {
      this.handleLinkedInError(value as string)
    }
    if (name === "dateOfBirth") {
      this.handleDateOfBirthError(value as any)
    }
    if (name === "numberAcquisitionClosed") {
      this.handleNumberAcquisitionError(value as any)
    }

    if (name === "numberProjectedAnnualAquisitions") {
      this.handleProjectedAquisitionsError(value as any)
    }
  };
  handleFirstNameError = (value: string) => {
    const arabicRegex =  /^[a-zA-Z\u0621-\u064A\u0660-\u0669\s]+$/;

    if (!arabicRegex.test(value)) {
      this.setState({ FirstNameError: this.state.pageText.pefn })
    } else if (value.length < 3) {
      this.setState({ FirstNameError: this.state.pageText.min })
    } else if (value.length > 20) {
      this.setState({ FirstNameError: this.state.pageText.twenty })
    } else {
      this.setState({ FirstNameError: "" })
    }
  }
  handleNumberAcquisitionError = (value: any) => {
    if (value.length > 6) {
      this.setState({ NumberAcquisitionError: this.state.pageText.max })
    } else {
      this.setState({ NumberAcquisitionError: "" })
    }
  }
  handleProjectedAquisitionsError = (value: any) => {
    if (value.length > 6) {
      this.setState({ ProjectedAquisitionsError: this.state.pageText.max })
    } else {
      this.setState({ ProjectedAquisitionsError: "" })
    }
  }
  handleAboutYourSelfError = (value: string) => {
    if (value.length > 200) {
      this.setState({ AboutYourselfError: this.state.pageText.two })
    } else {
      this.setState({ AboutYourselfError: "" })
    }
  }
  handleLastNameError = (value: string) => {
    const regex = /^[^ ].*$/;
    if (!regex.test(value)) {
      this.setState({ LastNameError: this.state.pageText.lName })
    } else if (value.length < 3) {
      this.setState({ LastNameError: this.state.pageText.min })
    } else if (value.length > 20) {
      this.setState({ LastNameError: this.state.pageText.twenty })
    } else {
      this.setState({ LastNameError: "" })
    }
  }
  handleDateOfBirthError = (value: any) => {
    const today = new Date();
    const inputDate = new Date(value);
    const ageDiff = today.getFullYear() - inputDate.getFullYear();
    let eighteenYearsAgo = new Date(today)
    eighteenYearsAgo.setFullYear(today.getFullYear() - 18);
    const monthDiff = today.getMonth() - inputDate.getMonth();
    const dateDiff = today.getDate() - inputDate.getDate();
    if (ageDiff < 18 || (ageDiff === 18 && (monthDiff < 0 || (monthDiff === 0 && dateDiff < 0)))) {
      this.setState({ InValidAge: `Age must be ${eighteenYearsAgo.toLocaleDateString('en-US')} or earlier` });
    } else {
      this.setState({ InValidAge: '' });
    }
  }
  handleLinkedInError = (value: string) => {
    const urlRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub)\/[a-zA-Z0-9_-]+\/?$/;
    if (urlRegex.test(value) || value.trim() == '') {
      this.setState({ IsValidURL: true })
      this.setState({ IsValidUrlMess: "" })
    } else {
      this.setState({ IsValidURL: false })
      this.setState({ IsValidUrlMess: this.state.pageText.lUrl })
    }
  }
  handleDocumentFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    this.setState({ selectedDocumentFile: file });

    // Additional actions with the selected file can be performed here.
  };

  handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const avatarFile = event.target.files && event.target.files[0];

    if (avatarFile) {
      this.setState({
        selectedAvatar: avatarFile,
      });

      // Additional actions with the selected avatar can be performed here.
    }
  };

  computedAvatarURL = () => {
    const { selectedAvatar, getAvatarURL } = this.state;
    return selectedAvatar ? URL.createObjectURL(selectedAvatar) : getAvatarURL;
  };

  hasDocument = () => {
    const {
      selectedDocumentFile,
      getDocumentURL,
    } = this.state;

    return !!getDocumentURL || !!selectedDocumentFile;
  }

  getRedirectionalUrl = async () => {
    if (this.props.sellerType) {
    let isUserSubscribed = await getStorageData('isUserSubscribed');
      if(isUserSubscribed != 'true') {
        return this.navigateToRoute("BuyerSubscription");
      }
      if (this.state.companyType == 'startup') {
        return this.navigateToRoute(`StartUpDetails`)
      }
      if (this.state.companyType == 'business') {
        return this.navigateToRoute(`CompanyProfileEdit`)
      }
    }
  }

  navigateToRoute = (urlstr: string) => {
    const msgVal = new Message(getName(MessageEnum.NavigationMessage));
    msgVal.addData(getName(MessageEnum.NavigationTargetMessage), urlstr);
    msgVal.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
    msgVal.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgVal);
  };

  handleCountryChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const selectedCountry = event.target.value as string;
    this.setState({
      selectedCountry,
      selectedCity: '', // Reset selected city when country changes
    });
  };

  handleCityChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const selectedCity = event.target.value as string;
    this.setState({ selectedCity });
  };
  handleDownload = () => {
    window.open(this.state.getDocumentURL, '_blank');
  };
}
