import React from "react"
import {Box,Button,Typography, withStyles, FormControl } from "@material-ui/core";
import ForgotEmailOTPController, { configJSON } from "./ForgotEmailOTPController.web";
import "./ForgotPassword.web.css";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
const images = require("./assets");
import {Styles} from "./Style.web";
//Customizable Area Start
//Customizable Area End

export class ForgotEmailOTP extends ForgotEmailOTPController {
    //Customizable Area Start
    otpFormHandler = () => {
        const { classes } = this.props;
        return (<form>
            <Box className={classes.firstRow}>
            <Typography variant="h3" className={classes.mainHeading_wrapper + " mainHeading"}>
            {this.getSpecifiedLangString(this.state.language, configJSON.str_title, "نسيت معرف البريد الإلكتروني")}
            </Typography>
            <Box className={classes.peraContainer}>
                <Typography variant="h6" className={classes.subpera}>
                    {this.getSpecifiedLangString(this.state.language, configJSON.str_pera1, " أدخل رقم هاتفك المحمول الذي استخدمته للتسجيل في المنصة.")}
                </Typography>
                <Typography className={classes.subpera} variant="h6" >
                    {this.getSpecifiedLangString(this.state.language, 
                        configJSON.str_pera2, 
                        `  سوف تتلقى رمزًا عبر رسالة نصية لاسترداد معرف البريد الإلكتروني الخاص بك`
                    )}
                </Typography>
            </Box>
            {this.state.otpResendMsg != "" && (<p className={classes.successMsg}>{this.state.otpResendMsg}</p>)}
            {this.state.errorMsg != "" && (
            <Box className={classes.errormsg}>
                <Typography className={classes.errormsgText}>{this.state.errorMsg}</Typography>
            </Box>)}
            <FormControl className={classes.formOtpInput}>
                <input
                    type="text"
                    placeholder={this.state.language === "ar" ? "أدخل الرمز" : "Enter Code"}
                    name="otp_code"
                    data-testid="otp_code"
                    onChange={this.handleChangeOTP}
                    value={this.state.otp_code}
                />
            </FormControl>
            
            <Box className={classes.timerBox}>
                <Box className={classes.timertext}>
                   {`Time Remaining ${String(this.state.counter.minutes).padStart(2, '0')}:
                   ${String(this.state.counter.seconds).padStart(2, '0')} s`}
                </Box>

             <Box className={classes.footerLink}>
                 <Button data-testid="reSendOtp" onClick={this.handleResendOTP} className={classes.link1}
                  disabled={this.state.counter.minutes > 0 || this.state.counter.seconds > 0}
                 >{this.getSpecifiedLangString(this.state.language, configJSON.notOtpLink, "لم تتلق رمزا؟")}</Button>
             </Box>
            </Box>

            <FormControl className={classes.formAction}>
                <Button data-testid="submitBtn" onClick={this.isSubmitted} className={(this.state.isValidOTP) ? classes.actveButton : classes.submitFormBtn}>
                    {this.getSpecifiedLangString(this.state.language, configJSON.submitTxt, "دخول")}
                </Button>
                <Box className={classes.moreAction}>
                    <p>
                        {this.getSpecifiedLangString(this.state.language, configJSON.newUser, "مستخدم جديد؟")}
                    </p>
                    <Button data-testid="goBackBtn" className={classes.signUpBtn} onClick={this.backToSignUp}>
                        <img src={images.user_accouunt} />
                        {this.getSpecifiedLangString(this.state.language, configJSON.signUpText, "اشتراك")}
                    </Button>
                </Box>
            </FormControl>
            </Box>
        </form>);
    }

    messageHandler = () => {
        const { classes } = this.props;
        return (
            <>
            <Box className={classes.firstRow}>
                <Typography variant="h3" className={classes.mainHeading_wrapper + " mainHeading"}>
                    {this.getSpecifiedLangString(this.state.language, configJSON.secTitle, "تمت استعادة معرف البريد الإلكتروني بنجاح")}
                </Typography>
            </Box>
            <Box className={classes.successLabel}>
                <img src={images.imageRight} alt="" />
                <span>{this.getSpecifiedLangString(this.state.language, configJSON.secKey, "نجاح!")}</span>
            </Box>
            <Box className={classes.peraContainer}>
                <Typography component="p" className={classes.subpera}>
                    {this.getSpecifiedLangString(this.state.language, configJSON.secPera1, ".لقد تم إرسال عنوان بريدك الإلكتروني إلى هاتفك")}
                </Typography>
                <Typography className={classes.subpera} component="p" >
                    {this.getSpecifiedLangString(this.state.language, configJSON.secPera2,  `  ارجع إلى صفحة تسجيل الدخول لتسجيل الدخول باستخدام بريدك الإلكتروني المسترد`)}
                </Typography>
            </Box>
            <FormControl className={classes.formAction}>
                <Box className={classes.moreAction}>
                    <Button data-testid="goLoginBtn" className={classes.signUpBtn} onClick={this.backToSignIn}>
                        {this.getSpecifiedLangString(this.state.language, configJSON.backToSignIn, "نجاح!")}
                    </Button>
                </Box>
            </FormControl>
            </>);
    }
    //Customizable Area End

    render() {
        //Customizable Area Start
        const { classes } = this.props;
        //Customizable Area End

        
        return (
        <>
        <HeaderBlock/>
        {/* Customizable Area Start */}
        <Box className="outer_wrapper">
            <Box  className="inner_wrapper">
                <div className={this.state.isSubmitted ? classes.messageBlockMain : classes.registrationBlockMain}>
                    <Box className={classes.containerForm + "mainconatiner"}>
                        <div className={classes.ForgotPassword_WhiteBox}>
                            {this.state.isSubmitted ? this.messageHandler() : this.otpFormHandler()}
                        </div>
                    </Box>
                </div>
            </Box>
        </Box>
        {/* Customizable Area End */}
        <FooterBlock/>
        </>
        );
        
    }
}

export default withStyles(Styles)(ForgotEmailOTP);