import React from "react"
import {Box,Button,Typography, withStyles } from "@material-ui/core";
import TermsConditionsController, { configJSON } from "./TermsConditionsController.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import {Styles} from "./Styles.web";
//Customizable Area Start
//Customizable Area End

export class TermsConditions extends TermsConditionsController {
 
  render() {
    //Customizable Area Start
    const { classes } = this.props;
    //Customizable Area End
    
    return (
    <>
    <HeaderBlock/>
    {/* Customizable Area Start */}
    <Box className={classes.wrapper}>
        <Typography component="h1" className={classes.pageTitle}>{this.state.languageState === "ar" ? this.state.titleAr : this.state.title}</Typography>
        <Box className={classes.outerWrapper}>
          <Box className={classes.innerWrapper}>
          {(this.state.languageState === "ar" ? this.state.descripAr : this.state.descrip).map((data: string) => 
            <div dangerouslySetInnerHTML={{__html: data}}></div>
            )}
          </Box>
        </Box>
        <Box className={classes.closeWrapper}>
          <Button data-testid="declineBtn" onClick={this.handleDeclineTermsCondition} className={classes.blankButton}>{this.state.languageState === "en" ? this.state.termsConditionsText.cancelButton : "رفض"}</Button>
          <Button data-testid="acceptBtn" onClick={this.handleAcceptTermsCondition} className={classes.closeButton}>{this.state.languageState === "en" ? this.state.termsConditionsText.saveButton : "قبول"}</Button>
        </Box>
    </Box>
    {/* Customizable Area End */}
    <FooterBlock/>
    </>
    );
  
  }
}

export default withStyles(Styles)(TermsConditions);
