// Customizable Area Start
import React, { useRef, useState } from "react";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Divider,
  FormControl,
  InputLabel,
  InputBase,
  Switch,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  IconButton,
  Grid,
  Tooltip,
  Modal,
  Dialog,
  Button,
  Popover,
  Container,
  createTheme,
  Link as NavTag,
  CircularProgress,
  styled
} from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import FooterBlock from "../../../components/src/FooterBlock.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import CatalogueController, { Props, configJSON, IStartUpPageText } from "./CatalogueController";
import { Link } from "react-router-dom";
import {
  businessLogo,
  rocketIcon,
  ExpandIcon,
  usersIcon,
  fileUpload,
  documentUpload,
  selectMenuIcon,
  calenderIcon,
  dot,
} from "./assets";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import {
  styles,
  Top_heading,
  TypographyCategory,
  CustomizeButton,
  ButtonTypography,
  DotImg,
  openBox
} from "../../categoriessubcategories/src/Categoriessubcategories.web";
import { conditionSort } from "../../../components/src/Helper";
import { toast } from "react-toastify";
import { userDefaultImage } from "../../../components/src/assets";
import { RemoveCircleOutline } from "@material-ui/icons";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
type StringOrNumberArray = string | string[] | number;

// Customizable Area End

export default class StartUpDetails extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  errorField = {
    fontFamily: "Montserrat",
    color: "#FF7575",
    marginTop: "0px",
    fontSize: "14px",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "24px",
    padding: "4px 15px",
    marginBottom: "0px",
    "@media (max-width:600px)": {
      marginTop: "4px",
      padding: "0"
    },
  };

  MuiInputLabelRoot = {
    color: "#8C8C8C",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    wordWrap: "break-word",
    transform: "translate(0, 1.5px)",
    "@media (max-width:1040px)": {
      fontSize: "15px",
    },
    "@media (max-width:980px)": {
      fontSize: "14px",
    },
    "@media (max-width:600px)": {
      fontSize: "13px",
    },
    "@media (max-width:500px)": {
      fontSize: "12px",
    },
    "@media (max-width:400px)": {
      fontSize: "11px",
    },
    
    right: 0,
    "& span": {
      color: "#FF7575",
    },
  };

  MuiInputBaseRoot = {
    margin: "30px 0 0",
    borderRadius: "12px",
    border: "1px #8C8C8C solid",
    "& .MuiInputBase-input": {
      padding: "12px 24px",
      color: "black",
      fontSize: "20px",
      fontFamily: "Montserrat",
      fontWeight: 400,
      wordWrap: "break-word",
    },
    "& input[type='date']::-webkit-calendar-picker-indicator": {
      opacity: 1,
      display: "block",
      backgroundImage: `url(${calenderIcon})`,
      backgroundSize: "cover",
      width: "28px",
      height: "28px",
    },
    "& input[type='date']:required:invalid::-webkit-datetime-edit": {
      color: "transparent",
    },
    "& input[type='date']:focus::-webkit-datetime-edit": {
      color: "black !important",
    },
  };

  CustomPopOver = styled(Popover)({
    "& .MuiPaper-rounded": {
      "@media (max-width:500px)": {
        width: "100% !important",
        boxSizing: "border-box",
        left: "0 !important"
      }
    }
  })

  startUpDetailsStyle = () => {
    return {
      margin: "30px auto",
      width: "90%",
      maxWidth: "1242px",
      "& .drop-down": {
        borderRadius: "20px",
        border: "1px black solid",
        width: "320px",
        height: "80px",
        marginBottom: "40px",
        "@media (max-width:500px)": {
          margin: "32px auto",
          width: "100%",
          boxShadow: "border-box"
        },
      },
      "& .responsive": {
        "@media (max-width:500px)": {
          margin: "32px auto",
          width: "100%",
          boxShadow: "border-box"
        },
      },
      "@media (max-width:600px)": {
        margin: "32px auto",
        width: "90%"
      },
     
    };
  };

  modalStyles = () => {
    return {
      margin: "auto",
      width: "100%",
      "& .MuiFormControl-root": {
        marginBottom: "20px",
        "& .MuiInputLabel-root": this.MuiInputLabelRoot,
        "& .MuiInputBase-root": this.MuiInputBaseRoot,
        "&.last": {
          marginBottom: "0",
        },
      },
      "& .upload-documents": {
        fontFamily: "Montserrat",
        fontWeight: 500,
        lineHeight: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#8C8C8C",
        fontSize: "20px",
        wordWrap: "break-word",
        cursor: "pointer",
        gap: "40px",
        marginTop: "30px",
        borderRadius: "20px",
        border: "2px #8C8C8C dashed",
        padding: "20px",
        "&:hover": {
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        },
      },
      "& .document-file": {
        borderRadius: "20px",
        border: "1px #8C8C8C solid",
        background: "rgba(140, 140, 140, 0.25)",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
        cursor: "pointer",
        "&:hover": {
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        },
      },
      "& .titleInSideBox": {
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 600,
        fontFamily: "Montserrat",
        "@media(max-width: 600px)": {
          fontSize: "18px !important"
        }
      },
      "& .imgStyles": {
        position: "relative",
        top: "20px",
        left: "150px",
        width: "24px",
        height: "24px",
        "@media(max-width: 600px)": {
          top: "10px !important",
          left: "90px !important",
          height: "14px !important",
          width: "14px !important",
        }
      },
      "& .responsiveBox": {
        gap: "30%",
        "@media(max-width: 600px)": {
          gap: "10%"
        }
      }
    }
  }

  getPrivateInformation = () => {
    const style = () => {
      return {
        marginTop: "24px",
        width: "100%",
        "& .startup-profile-section": {
          display: "flex",
          gap: "100px",
          marginBottom: "24px",
          "@media (max-width:620px)": {
            flexDirection: " column",
            gap: "20px",
          },
          "& .profile-section": {
            borderRadius: "12px",
            border: "1px #8C8C8C solid",
            minWidth: "320px",
            padding: "52px 40px",
            display: "flex",
            gap: "32px",
            "@media (max-width:620px)": {
              minWidth: "150px",
              padding: "28px 20px"

            },
            "& img": {
              width: "100px",
              height: "100px",
              "@media (max-width:620px)": {
                width: "56px",
                height: "56px",

              },
            },
            "& .profile-details": {
              display: "grid",
              justifyItems: "start",
              gap: "39px",
              "& .user-name": {
                color: "black",
                fontSize: "24px",
                fontFamily: "Montserrat",
                fontWeight: 700,
                lineHeight: "24px",
                wordWrap: "break-word",
                "@media (max-width:620px)": {
                  fontSize:'16px',
                  lineHeight:"18px"
  
                },
              },
              "& .view-link": {
                color: "#262626",
                fontSize: "16px",
                fontFamily: "Montserrat",
                fontWeight: 500,
                textDecoration: "underline",
                lineHeight: "24px",
                wordWrap: "break-word",
                cursor: "pointer",
                "@media (max-width:620px)": {
                  fontSize:'14px',
                  lineHeight:"16px"
  
                },
              },
            },
          },
          "& .startup-section": {
            "& .MuiInputLabel-root": this.MuiInputLabelRoot,
            "& .MuiInputBase-root": this.MuiInputBaseRoot,
            "& .mb": {
              marginBottom: "24px",
            },
          },
        },
        "& .MuiDivider-root": {
          backgroundColor: "#8C8C8C !important",
        },
        "& .competitors-section": {
          margin: "19px 0 12px",
          "& .MuiInputLabel-root": this.MuiInputLabelRoot,
          "& .MuiInputBase-root": this.MuiInputBaseRoot,
          "@media (max-width:600px)": {
            margin: "14px 0 20px",
          }
        },
        "& .p-l-section": {
          margin: "12px 0 12px",
          "& .MuiInputLabel-root": this.MuiInputLabelRoot,
          "& .upload-document": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "40px",
            marginTop: "30px",
            borderRadius: "20px",
            border: "2px #8C8C8C dashed",
            padding: "20px",
            color: "#8C8C8C",
            fontSize: "20px",
            fontFamily: "Montserrat",
            fontWeight: 500,
            lineHeight: "24px",
            wordWrap: "break-word",
            cursor: "pointer",
            "&:hover": {
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
        "& .documents-files-section": {
          marginBottom: "20px",
          "& .MuiInputLabel-root": this.MuiInputLabelRoot,
          "& .documents-files": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
            borderRadius: "20px",
            border: "1px #8C8C8C solid",
            background: "rgba(140, 140, 140, 0.25)",
            padding: "20px",
            cursor: "pointer",
            "&:hover": {
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
        "& .auto-sign-section": {
          margin: "20px 0 0",
          width: "100%",
          "@media (max-width-620px)": {
            margin: "0",
          },
          "& .MuiInputLabel-root": this.MuiInputLabelRoot,
          "& .MuiFormGroup-root": {
            marginTop: "24px",
            flexDirection: "unset",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 30px",
            "@media (max-width-440px)": {
              padding: "0",
              gap: "0px",
            },
            "& .MuiFormControlLabel-root": {
              margin: "0",
              gap: "145px",
              "@media (max-width-440px)": {
                gap: "0px"
              },
              "& .MuiTypography-root": {
                color: "#8C8C8C",
                fontSize: "20px",
                fontFamily: "Montserrat",
                fontWeight: 700,
                lineHeight: "24px",
                wordWrap: "break-word",
              },
              "& .MuiFormControlLabel-label.Mui-disabled": {
                opacity: 0.6,
              },
              "& .MuiSwitch-root": {
                width: "84px",
                height: "40px",
                padding: "0",
                borderRadius: "20px",
                "@media (max-width-440px)": {
                  width: "42px",
                  height: "20px"
                },
                "& .MuiButtonBase-root": {
                  padding: "4px 5px",
                  "& .MuiSwitch-thumb": {
                    width: "32px",
                    height: "32px",
                  },
                },
                "& .MuiSwitch-colorSecondary.Mui-checked": {
                  color: "#fff",
                },
                "& .MuiSwitch-switchBase.Mui-checked": {
                  transform: "translateX(42px)",
                },
                "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#5412FC",
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  opacity: 1,
                },
                "& .MuiSwitch-track": {
                  backgroundColor: "#8C8C8C",
                  opacity: 1,
                },
                "& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
                  opacity: 0.4,
                },
                "& .MuiSwitch-colorSecondary.Mui-disabled": {
                  opacity: 0.8,
                  color: "white",
                },
              },
            },
            "& .MuiDivider-vertical": {
              height: "80px",
            },
          },
        },
      };
    };

    const onChangeMultiChangesOnInput = (value: string, index: number) => {
      const updatedProduct = [...this.state.competitors];
      updatedProduct[index] = value;
      this.setState({ competitors: updatedProduct });
    }

    const addNewProduct = () => {
      const updatedProduct = [...this.state.competitors, ""];
      this.setState({ competitors: updatedProduct });
    }
    return (
      <>
        <Box sx={style()}>
          <div className="startup-profile-section">
            <div className="profile-section">
            <img src={conditionSort(this.state.prfileImagePath, this.state.prfileImagePath, userDefaultImage)} alt="profile-image" />
              <div className="profile-details">
                <div className="user-name">{this.state.nameOftheUser}</div>
                <Link to="/SellerProfile" className="view-link">{this.state.startUpPageText.viewFullProfileLink}</Link>
              </div>
            </div>
            <div className="startup-section">
              <FormControl fullWidth className="mb">
                <InputLabel shrink htmlFor="startup-name-input">
                  {this.getContent(this.state.startUpPageText.startUpNameText,configJSON.startUpNameTextArabic)} <span>{configJSON.startText}</span>
                </InputLabel>
                <InputBase id="startup-name-input"
                  data-test-id="startup-name-input"
                  value={this.state.startupName}
                  onChange={(e) => this.setState({ startupName: e.target.value })}
                  style={{ border: this.getBorderColorPrivateInfo(this.state.privateInfoFlag,"startupName", this.state.startupName) }}
                />
                { this.state.privateInfoFlag && this.shouldDisplayErrorMessagePrivateInfo("startupName",this.state.startupName)}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="startup-website-input">
                  {this.getContent(this.state.startUpPageText.startUpWebsiteText,configJSON.startUpWebsiteTextArabic)}<span>{configJSON.startText}</span>
                </InputLabel>
                <InputBase id="startup-website-input"
                  value={this.state.startupWebsite}
                  data-test-id="startup-website-input"
                  onChange={(e) => this.setState({ startupWebsite: e.target.value })}
                  style={{ border: this.getBorderColorPrivateInfo(this.state.privateInfoFlag,"startupWebsite", this.state.startupWebsite)}}
                />
                {this.state.privateInfoFlag && this.shouldDisplayErrorMessagePrivateInfo("startupWebsite",this.state.startupWebsite)}
              </FormControl>
            </div>
          </div>
          <Divider />
          <div className="competitors-section" >
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="products-input">
             {this.getContent(this.state.startUpPageText.whoAreYourCompitatorsText, configJSON.whoAreYourCompitatorsTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <div style={{ width: "100%", marginTop: "20px" }} className="number">
              {this.state.competitors.map((value, index) => (
                <div key={index} style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{marginTop: "50px", width: "10%", textAlign: "center", justifyContent: "center", }}>
                      <Typography style={{ color:"#000000"}}>{index+1}.</Typography>
                  </div>
                  <div style={{justifyContent: "center", width: "90%", display: "flex", position: "relative" }}>
                      <InputBase id={`comp-input${index}`}
                        fullWidth
                        data-test-id={`comp-input-${index}`}
                        value={value}
                        style={{ height: "40px", textAlign: "center" , border:this.state.privateInfoFlag ?this.getBorderErrorCompetitors(value):""}}
                        onChange={(e) => { onChangeMultiChangesOnInput(e.target.value, index) }}
                      />
                      {index ? <IconButton style={{position: "absolute", bottom: 0, right: 0}} onClick={() => this.removeProduct(index)}
                        data-test-id="add-icon">
                        <RemoveCircleOutline
                          style={{ fontSize: 30 }} 
                          />
                      </IconButton> : ""}
                  </div>
                </div>
              ))}
            </div>
            {this.state.privateInfoFlag && this.shouldDisplayErrorMessagePrivateInfo("competitors",this.state.competitors)}
            <div style={{marginLeft: "25px", marginRight: this.state.language ==="ar" ? "25px":"0px"}}>
              <IconButton onClick={addNewProduct}
                data-test-id="add-icon">
                <AddCircleOutlineIcon 
                  style={{ fontSize: 30 }} 
                  />
              </IconButton>
            </div>
          </FormControl>
          </div>
          <Divider />
          <div className="p-l-section" style={{marginTop: "32px"}}>
            <FormControl fullWidth >
              <InputLabel shrink htmlFor="p-l-input">
                {this.getContent(this.state.startUpPageText.pAndLStatmentText,configJSON.pAndLStatmentTextArabic)} <span>{configJSON.startText}</span>
              </InputLabel>
              <input type="file" id="p-l-input" hidden
                // accept=".pdf,.doc,.docx"
                onChange={(e) => {
                  const file = e.target.files && e.target.files[0];
                  if (file) {
                    this.setState({ plStatement: file });
                  }
                }}
                data-test-id="p-l-input"
              />
              <label htmlFor="p-l-input" className="upload-document" style={{ border: this.state.privateInfoFlag ? this.getBorderplstatement(this.state.plStatement):""}}>
                <img src={fileUpload} />
                {this.state.plStatement ? this.state.plStatement.name : this.state.startUpPageText.uploadDocumentText}
              </label>
              {this.state.privateInfoFlag && this.shouldDisplayErrorMessagePrivateInfo("plStatement", this.state.plStatement)}
            </FormControl>
          </div>
          <div className="documents-files-section">
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="documents-files-input">
                {this.getContent(this.state.startUpPageText.documentsAndFilesText,configJSON.documentsAndFilesTextArabic)}
              </InputLabel>
              {this.state.arrayFiles.map((ele) => (
                <div className="p-l-section">
                  <FormControl fullWidth>
                    <Typography style={{ fontSize: "20px", marginTop: "10px" }}>{ele.title}</Typography>
                    <label className="upload-document" style={{ marginTop: 0 }}>
                      <img src={fileUpload} />
                      {ele.uploadedFile ? ele.uploadedFile.name : this.state.startUpPageText.uploadDocumentText}
                    </label>
                  </FormControl>
                </div>
              ))}
              <button id="documents-files-input" data-test-id="file-upload-modal" className="documents-files" onClick={() => this.setState({ openFileModal: true })}>
                <img src={documentUpload} />
              </button>
            </FormControl>
          </div>
          <Divider />
          <div className="auto-sign-section">
            <FormControl fullWidth>
              <InputLabel shrink>{this.getContent(this.state.startUpPageText.autoSignText,configJSON.autoSignTextArabic)}</InputLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Switch id="switch" name="jason" checked={this.state.autoSignNDA}
                    onChange={(event) => this.handleSwitchChange(event.target.checked)}

                  />}
                  id="auto-sign-input"
                  label={this.getContent(this.state.startUpPageText.autoSignInLabelNda,configJSON.autoSignInLabelNdaArabic)}
                  labelPlacement="start"
                />
                <Divider orientation="vertical" />
                <FormControlLabel
                  control={<Switch name="jason"
                    checked={this.state.autoAcceptRequest}
                    onChange={(event) => this.setState({ autoAcceptRequest: event.target.checked })}
                  />}
                  id="auto-sign-input-1"
                  label={this.getContent(this.state.startUpPageText.autoSignAcceptText,configJSON.autoSignAcceptTextArabic)}
                  labelPlacement="start"
                  disabled={!this.state.autoSignNDA}
                />
              </FormGroup>
            </FormControl>
          </div>
          <Divider />
        </Box>
      </>
    );
  };

  style = () => {
    return {
      "& .staticTextAr": {
        position: "absolute",
        fontFamily: "Montserrat",
        top: "50%",
        right: "10px",
        transform: "translateY(-50%)",
        pointerEvents: "none",
        color: "black",
        fontSize: "14px",
        marginTop: "16px"
      },
      "& .staticText": {
        position: "absolute",
        top: "50%",
        fontFamily: "Montserrat",
        left: "10px",
        transform: "translateY(-50%)",
        pointerEvents: "none",
        color: "black",
        fontSize: "14px",
        marginTop: "16px"
      },
      marginTop: "24px",
      width: "100%",
      "& .popper": {
        background: "#fff",
        color: "#262626",
        "& .MuiTooltip-popper": {
          minWidth: "100px",
        },
      },
      "& .MuiFormControl-root": {
        marginBottom: "12px",
        "& .MuiInputLabel-root": this.MuiInputLabelRoot,
        "& .MuiInputBase-root": this.MuiInputBaseRoot,
        "&.last": {
          marginBottom: "0",
        },
      },
    };
  };

  getSummary = () => {
    let w3l3AselectCities; if(this.state.language == 'en'){
      w3l3AselectCities = (configJSON.selectedcityList.find((country: { country: string }) => country?.country.toLowerCase() === this.state.country.toLowerCase())?.cities || []).map((city: string) => ({ value: city, title: city }))
    }else {
      w3l3AselectCities = (configJSON.countriesDataAr.find((country: { country: string }) => country?.country.toLowerCase() === this.state.country.toLowerCase())?.cities || []).map((city: string) => ({ value: city, title: city }));

    }
    return (
      <>
        <Box sx={this.style()}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="date-founded-input">
              {this.getContent(this.state.startUpPageText.dateFoundedText,configJSON.dateFoundedTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <InputBase id="date-founded-input" type="date" required
              value={this.state.dateFounded}
              data-test-id="date-founded-input"
              onChange={(e) => { this.setState({ dateFounded: e.target.value }) }}
              style={{ border: this.getBorderColorInfo(this.state.summaryFlag,"dateFounded", this.state.dateFounded) }}
            />
            {this.state.summaryFlag && this.shouldDisplayErrorMessageInfo("dateFounded", this.state.dateFounded)}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="startup-team-size-input">
              {this.getContent(this.state.startUpPageText.startUpTeamSizeText,configJSON.startUpTeamSizeTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <InputBase id="startup-team-size-input" 
              value={this.getValueFinance(this.state.startupTeamSize.toString())}
              onChange={(e)=>this.getHandleChange(e,'startupTeamSize')}
              data-test-id="startup-teamsize-input"
              style={{ border: this.getBorderColorInfo(this.state.summaryFlag,"startupTeamSize", this.state.startupTeamSize) }}
            />
            {this.state.summaryFlag && this.shouldDisplayErrorMessageInfo("startupTeamSize", this.state.startupTeamSize)}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="date-type-category-input">
              {this.getContent(this.state.startUpPageText.startUpTypeText,configJSON.startUpTypeTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <SelectMenu menuList={this.extractStartupSubCategories()}
              selectedValue={`${this.state.startupTypeCategory}`}
              onOptionClick={(value) => this.setState({ startupTypeCategory: value })}
              error={this.state.summaryFlag && !this.state.startupTypeCategory }
              data-test-id="startup-cat-input"
            />
            {this.state.summaryFlag && this.shouldDisplayErrorMessageInfo("startupTypeCategory", this.state.startupTypeCategory)}
          </FormControl>
          {conditionSort(this.state.otherSubCatId == this.state.startupTypeCategory, 
            <FormControl fullWidth>
            <InputLabel shrink htmlFor="headline-input">
              {this.state.startUpPageText.otherCatText} <span>*</span>
            </InputLabel>
            <InputBase id="otherCatText-input" value={this.state.otherCatText ? this.state.otherCatText : ""}
              onChange={(e) => { this.setState({ otherCatText: e.target.value }) }}
              data-test-id="otherCatText-input"
              style={{ border: this.getBorderColorInfo(this.state.summaryFlag,"otherCatText", this.state.otherCatText) }}
            />
            {this.state.summaryFlag && this.shouldDisplayErrorMessageInfo("otherCatText", this.state.otherCatText)}
          </FormControl>, null)}
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="country-input">
              {this.getContent(this.state.startUpPageText.countryText,configJSON.countryTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <SelectMenu menuList={conditionSort(this.state.language === "ar", configJSON.selectedMenuListAr, this.state.selectedMenuList) }
              selectedValue={this.state.country}
              error={this.state.summaryFlag && !this.state.country}
              onOptionClick={(value) => this.setState({ country: value ,city:'' })}
              data-test-id="country"
            />
            {this.state.summaryFlag && this.shouldDisplayErrorMessageInfo("country", this.state.country)}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="city-input">
              {this.getContent(this.state.startUpPageText.CityText, configJSON.cityTextArabic)}<span>{configJSON.startText}</span>
            </InputLabel>
            <SelectMenu
            menuList={[...w3l3AselectCities]}
              disabled={!this.state.country} selectedValue={this.state.city}
              error={ this.state.summaryFlag && !this.state.city}
              onOptionClick={(value) => this.setState({ city: value })}
              data-test-id="city"
            />
            {this.state.summaryFlag && this.shouldDisplayErrorMessageInfo("city", this.state.city)}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="headline-input">
              {this.getContent(this.state.startUpPageText.headingText, configJSON.headingTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <InputBase id="headline-input" value={this.state.headline}
              onChange={(e) => { this.setState({ headline: e.target.value }) }}
              data-test-id="headline-input"
              style={{ border: this.getBorderColorInfo(this.state.summaryFlag,"headline", this.state.headline) }}
            />
            {this.state.summaryFlag && this.shouldDisplayErrorMessageInfo("headline", this.state.headline)}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="description-input">
              {this.getContent(this.state.startUpPageText.DescriptionText,configJSON.descriptionTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <InputBase id="description-input" multiline rows={3}
              value={this.state.description}
              data-test-id="description"
              onChange={(e) => { this.setState({ description: e.target.value }) }}
              style={{ border: this.getBorderColorInfo(this.state.summaryFlag,"description", this.state.description)}}
            />
            {this.state.summaryFlag && this.shouldDisplayErrorMessageInfo("description", this.state.description)}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="asking-price-input">
              {this.getContent(this.state.startUpPageText.AskingPriceText,configJSON.askingPriceArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText")}>{conditionSort(this.state.language == 'en', 'SAR', "ر.س")}</span>
            <InputBase id="asking-price-input"
              value={this.getValueFinance(this.state.askingPrice)}
              data-test-id="asking-price-input"
              onChange={(e)=>this.getHandleChange(e,'askingPrice')}
              style={conditionSort(this.state.language == 'ar', {
                paddingRight: "40px",
                border: this.getBorderColorInfo(this.state.summaryFlag,"askingPrice", this.state.askingPrice)
              }, {
                paddingLeft: "40px",
                border: this.getBorderColorInfo(this.state.summaryFlag,"askingPrice", this.state.askingPrice)
              })}
            />
            {this.state.summaryFlag && this.shouldDisplayErrorMessageInfo("askingPrice", this.state.askingPrice)}
          </FormControl>
          <FormControl fullWidth className="last">
            <InputLabel shrink htmlFor="asking-price-reasoning-input">
              {this.getContent(this.state.startUpPageText.AskingPriceReasonText, configJSON.askingPriceReasonTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <InputBase id="asking-price-reasoning-input" multiline rows={3}
              value={this.state.askingPriceReasoning}
              data-test-id="asking-price-reason-input"
              onChange={(e) => { this.setState({ askingPriceReasoning: e.target.value }) }}
               style={{ border: this.getBorderColorInfo(this.state.summaryFlag,"askingPriceReasoning", this.state.askingPriceReasoning)}}
            />
            {this.state.summaryFlag && this.shouldDisplayErrorMessageInfo("askingPriceReasoning", this.state.askingPriceReasoning)}
          </FormControl>
        </Box>
      </>
    );
  };
  getBorderColorPrivateInfo = (flag:boolean, field:string, value: StringOrNumberArray ) => {
    const errorData= this.validateFieldPrivateInfo(field,value);
    if(flag){
      return ( errorData ? '' :'1px solid red' );
    }
    else{
      return "";
    }
  };
getBorderColorInfo = (flag:boolean, field:string, value: StringOrNumberArray ) => {
    const errorData= this.validateFieldSummary(field,value);
    if(flag){
      return ( errorData ? '' :'1px solid red' );
    }
    else{
      return "";
    }
  };
getBorderErrorCompetitors=(value:any) =>(/^[a-zA-Z0-9\s]+$/.test(value.trim())? "" :'1px solid red');
getBorderplstatement=(value:any)=>((!value ? "1px red dashed" : "" ));
shouldDisplayErrorMessageInfo = (summaryErrorData:string, value:StringOrNumberArray) =>{
 
  const errorMessage = this.getErrorMessage(summaryErrorData, value);
  return summaryErrorData  && (
    <Box sx={this.errorField}>{errorMessage}</Box>
    )
}
shouldDisplayErrorMessagePrivateInfo = (privateErrorData:string, value:StringOrNumberArray) =>{
  const errorMessage = this.getErrorMessagePrivateInfo(privateErrorData, value);
  return privateErrorData  && (
    <Box sx={this.errorField}>{errorMessage}</Box>
    )
}
getBorderColorCompany = (flag:boolean, field:string, value:string |number) => {
  const errorData= this.validateFieldCompany(field,value);

  if(flag){
    return ( errorData ? '' :'1px solid red' );
  }
  else{
    return "";
  }
  
};

getValueFinance=(value:any)=> {
 
  const parts = value.toString().split('.');
  let integerPart = parts[0];
  const fractionalPart = parts.length > 1 ? `.${parts[1]}` : '';

  let result = '';
  let count = 0;

  for (let i = integerPart.length - 1; i >= 0; i--) {
    result = integerPart[i] + result;
    count++;
    if (count === 3 && i !== 0) {
      result = ',' + result;
      count = 0;
    }
  }

  return result + fractionalPart;
}
getHandleChange=(e:any,field:any)=>{
 
  const main_value = e.target.value.replace(/,/g, '');

  if(field==='startupTeamSize'){
    this.setState({ startupTeamSize: main_value })
  }else if(field==='askingPrice'){
    this.setState({ askingPrice: main_value })
  }else if(field==='ltmgross'){
    this.setState({ ltmgross: main_value })
  }else if(field==='ltmnet'){
    this.setState({ ltmnet: main_value })
  }else if(field==='annualRecurringRevenue'){
    this.setState({ annualRecurringRevenue: main_value })
  }else if(field==='annualGrowthRate'){
    this.setState({ annualGrowthRate: main_value })
  }else if(field==='lastMonthGrossRevenue'){
    this.setState({ lastMonthGrossRevenue: main_value })
  }else if(field==='lastMonthNetProfit'){
    this.setState({ lastMonthNetProfit: main_value })
  }else if(field==='numberOfUsers'){
    this.setState({ numberOfUsers: main_value })
  }

}
shouldDisplayErrorMessageCompany = (field: string, value: string | number) =>{
const errorMessage = this.getErrorMessageCompany(field, value);
return field  && (
  <Box sx={this.errorField}>{errorMessage}</Box>
  )
}
getBorderColor = (companyError:boolean, value:string | number) => companyError && !value ? "1px solid red" : "";

shouldDisplayErrorMessage = (companyError:boolean, value:StringOrNumberArray) =>( companyError && !value && <Box sx={this.errorField}>{conditionSort(this.state.language == 'en', 'This field is required', `هذا الحقل مطلوب`)}</Box>)

getBorderColorFinance = (flag:boolean, field:string, value: boolean | string) => {
  const errorData= this.validateFieldFinance(field,value);

  if(flag){
    return ( errorData ? '' :'1px solid red' );
  }
  else{
    return "";
  }
  
};

shouldDisplayErrorMessageFinance = (field: string, value: string ) =>{
const errorMessage = this.getErrorMessageFinance(field, value);
return field  && (
  <Box sx={this.errorField}>{errorMessage}</Box>
  )
}
getBorderColorMetrics = (field:string, value:string | number) => {
  const errorData= this.validateFieldMetricsData(field,value);
  return ( errorData ? '' :'1px solid red' );
};

shouldDisplayErrorMessageMetrics = (field:string, value: string |number) =>{
const errorMessage = this.getErrorMessageMetrics(field, value);
return field  && (
  <Box sx={this.errorField}>{errorMessage}</Box>
  )
}
onFileChange = (e:any) => {
  const file = e.target.files && e.target.files[0];
  if (file) {
    this.setState({ modalFile: file });
  }
}

  getCompanyOverView = () => {
    const style = () => {
      return {
        marginTop: "52px",
        width: "100%",
        "& .number": {
          marginLeft: "-45px",
          "@media (max-width:600px)": {
            marginLeft: 0,
          },
          "& .icon-styles": {
            "@media (max-width:600px)": {
              marginLeft: 0
            }
          },
        },
        "& .input": {
          "& .focus": {
            outline: "none"
          }
        },
        "& .MuiFormControl-root": {
          marginBottom: "42px",
          "& .MuiInputLabel-root": this.MuiInputLabelRoot,
          "& .MuiInputBase-root": this.MuiInputBaseRoot,
          "&.last": {
            marginBottom: "0",
          },

        },
      };
    };
    

    const onChangeMultiLine = (value: string, index: number) => {
      const updatedProducts = [...this.state.products];
      updatedProducts[index] = value;
      this.setState({ products: updatedProducts });
    }

    const addNewProduct = () => {
      const updatedProducts = [...this.state.products, ""];
      this.setState({ products: updatedProducts });
    }

    return (
      <>
        <Box sx={style()}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="business-modal">
              {this.getContent(this.state.startUpPageText.businessModelText,configJSON.businessModelTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <InputBase id="business-input" required multiline rows={4}
              value={this.state.businessModelAndPricing}
              data-test-id="business-input"
              onChange={(e) => this.setState({ businessModelAndPricing: e.target.value })}
              style={{ border: this.getBorderColorCompany(this.state.companyFlag,"businessModelAndPricing", this.state.businessModelAndPricing)}}
            />
             {this.state.companyFlag && this.shouldDisplayErrorMessageCompany("businessModelAndPricing", this.state.businessModelAndPricing)}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="techstack-input">
              {this.getContent(this.state.startUpPageText.techStackText, configJSON.techStackTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <InputBase id="techstack-input" required multiline rows={4}
              value={this.state.techStack}
              onChange={(e) => this.setState({ techStack: e.target.value })}
              data-test-id="techstack-input"
              style={{ border: this.getBorderColorCompany(this.state.companyFlag,"techStack", this.state.techStack)}}
            />
              {this.state.companyFlag && this.shouldDisplayErrorMessageCompany("techStack", this.state.techStack)}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="products-input">
              {this.getContent(this.state.startUpPageText.listOfProductsText, configJSON.listOfProductsTextArabic)}
            </InputLabel>
            <div style={{ width: "100%", marginTop: "20px" }} className="number">
              {this.state.products.map((value, index) => (
                <div key={index} style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ textAlign: "center", justifyContent: "center", marginTop: "50px", width: "10%" }}>
                    <Typography style={{ color: "#000000" }}>{index + 1}.</Typography>
                  </div>
                  <div style={{ width: "90%", display: "flex", justifyContent: "center" }}>
                    <InputBase id={`products-input${index}`}
                      fullWidth
                      value={value}
                      style={{ height: "40px", textAlign: "center" }}
                      onChange={(e) => { onChangeMultiLine(e.target.value, index) }}
                      data-test-id={`products-input-${index}`}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div style={{ marginLeft: '-17px', marginRight: this.state.language ==="ar" ? "10px": 0 }}>
              <IconButton onClick={addNewProduct} data-test-id="add-icon">
                <AddCircleOutlineIcon style={{ fontSize: 30 }} />
              </IconButton>
            </div>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="country-input">
              {this.getContent(this.state.startUpPageText.growthOppText, configJSON.growthOppTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <MultiCheckBox options={this.getContent(this.state.growthAppartunitiesValues,configJSON.growthOpportunitiesArabic) as {title:string, value:string}[]}
              selected={this.state.growthOpportunity}
              onCheck={(value) => { this.setState({ growthOpportunity: value }) }}
              error={this.state.companyFlag ? !this.state.companyError.growthOpportunity :false}
              inputValue={this.state.growOppurtunityOtherValue} onOtherCheckUpdate={(e) => this.setState({ growthOpputunityOther: e, growOppurtunityOtherValue: "" })}
              otherChecked={this.state.growthOpputunityOther}
              valueUpdate={(e) => this.setState({ growOppurtunityOtherValue: e })}
              data-test-id="growth-checkbox"
              otherText={this.getContent(this.state.startUpPageText.otherText,configJSON.otherTextArabic) as string}
              language={this.state.language}
            />
             {this.state.companyFlag && this.shouldDisplayErrorMessageCompany("growthOpportunity", this.state.growthOpportunity.length)}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="country-input">
              {this.getContent(this.state.startUpPageText.keyAssestsText,configJSON.keyAssestsTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <MultiCheckBox
              options={this.getContent(this.state.keyAssestsValues,configJSON.keyAssetsArabic) as {title:string, value:string}[]}
              selected={this.state.keyAssets}
              onCheck={(value) => { this.setState({ keyAssets: value }) }}
              error={this.state.companyFlag ? !this.state.companyError.keyAssets:false}
              inputValue={this.state.keyAssestsOtherValue} onOtherCheckUpdate={(e) => this.setState({ keyAssestsOther: e, keyAssestsOtherValue: "" })}
              otherChecked={this.state.keyAssestsOther}
              valueUpdate={(e) => this.setState({ keyAssestsOtherValue: e })}
              data-test-id="key-checkbox"
              otherText={this.getContent(this.state.startUpPageText.otherText,configJSON.otherTextArabic) as string}
              language={this.state.language}
            />
              {this.state.companyFlag && this.shouldDisplayErrorMessageCompany("keyAssets", this.state.keyAssets.length)}
          </FormControl>
        </Box>
      </>
    );
  };

  getAquisitionDetails = () => {
    
    return (
      <>
        <Box sx={this.style()}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="techstack-input">
              {this.getContent(this.state.startUpPageText.reasonForSellingText,configJSON.reasonForSellingTextArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <InputBase
              id="techstack-input"
              data-test-id="reasonforselling-input"
              required
              multiline
              rows={4}
              value={this.state.reasonForSelling}
              onChange={(e) => this.setState({ reasonForSelling: e.target.value })}
              style={{ border: this.getBorderColor(this.state.acquisitionError, this.state.reasonForSelling) }}
            />
            {this.shouldDisplayErrorMessage(this.state.acquisitionError, this.state.reasonForSelling)}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="country-input">
              {this.getContent(this.state.startUpPageText.finanacialSources,configJSON.finanacialSourcesArabic)} <span>{configJSON.startText}</span>
            </InputLabel>
            <MultiCheckBox
              options={this.getContent(this.state.financialSoucesValues, configJSON.financialSourcesArabic) as {title:string, value:string}[]}
              selected={this.state.financialSources}
              onCheck={(value) => { this.setState({ financialSources: value }) }}
              error={this.state.acquisitionError}
              inputValue={this.state.financialSoucesValue}
              otherChecked={this.state.financialSoucesOther}
              valueUpdate={(e) => this.setState({ financialSoucesValue: e })}
              onOtherCheckUpdate={(e) => this.setState({ financialSoucesOther: e, financialSoucesValue: "" })}
              data-test-id="financialsources-checkbox"
              otherText={this.getContent(this.state.startUpPageText.otherText,configJSON.otherTextArabic) as string}
              language={this.state.language}
            />
            
            {this.shouldDisplayErrorMessage(this.state.acquisitionError, (!this.state.financialSoucesValue.length ||
        !this.state.financialSources.length) ? "" : "true")}
          </FormControl>
        </Box>

      </>
    )
  }

  getFinancialDetails = () => {
    const useStyles = () => this.style();

    const tooltip = (title: JSX.Element) => {
      return (
        <Tooltip title={title} arrow placement="top"
          className="popper"
        >
          <IconButton>
            <InfoOutlinedIcon style={{ color: "grey" }} />
          </IconButton>
        </Tooltip>
      )
    }
    return (
      <Box sx={useStyles()}>
        <FormControl fullWidth >
          <InputLabel shrink htmlFor="ltmgross-input">
            {this.getContent(this.state.startUpPageText.ltmGrassRevenueText,configJSON.ltmGrassRevenueTextArabic)} <span>{configJSON.startText}</span> <span>
              {tooltip(<Typography>{this.getContent(this.state.startUpPageText.ltmGrassToolTIpText, configJSON.ltmGrossToolTipTextArabic)}</Typography>)}
            </span>
          </InputLabel>
          <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText")}>{conditionSort(this.state.language == 'en', 'SAR', "ر.س")}</span>
          <InputBase id="ltmgross-input" required 
            value={this.getValueFinance(this.state.ltmgross)}
            onChange={(e)=>this.getHandleChange(e,'ltmgross')}
            data-test-id="ltmgross-input"
            style={conditionSort(this.state.language == 'ar', {
              paddingRight: "40px",
              marginTop: "42px",
              border: this.getBorderColorFinance(this.state.financeFlag,"grossRevenue", this.state.ltmgross)
            }, {
              paddingLeft: "40px",
              marginTop: "42px",
              border: this.getBorderColorFinance(this.state.financeFlag,"grossRevenue", this.state.ltmgross)
            })}
          />
          {this.state.financeFlag && this.shouldDisplayErrorMessageFinance("grossRevenue", this.state.ltmgross)}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="ltmnet-input">
            {this.getContent(this.state.startUpPageText.ltmNetProfitText,configJSON.ltmNetProfitTextArabic)}<span>{configJSON.startText}</span> <span>
              {tooltip(<Typography>{this.getContent(this.state.startUpPageText.ltmNetProfitToolTipText,configJSON.ltmNetProfitToolTipTextArabic)}</Typography>)}
            </span>
          </InputLabel>
          <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText")}>{conditionSort(this.state.language == 'en', 'SAR', "ر.س")}</span>
          <InputBase id="ltmnet-input" required
            value={this.getValueFinance(this.state.ltmnet)}
            onChange={(e)=>this.getHandleChange(e,'ltmnet')}
            data-test-id="ltmnet-input"
            style={conditionSort(this.state.language == 'ar', {
              paddingRight: "40px",
              marginTop: "42px",
              border:  this.getBorderColorFinance(this.state.financeFlag,"netProfit", this.state.ltmnet)
            }, {
              paddingLeft: "40px",
              marginTop: "42px",
              border:  this.getBorderColorFinance(this.state.financeFlag,"netProfit", this.state.ltmnet)
            })}
          />
          {this.state.financeFlag && this.shouldDisplayErrorMessageFinance("netProfit", this.state.ltmnet)}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="annual-input">
            {this.getContent(this.state.startUpPageText.annualRecurringRevenueText,configJSON.annualRecurringRevenueTextArabic)} <span>{configJSON.startText}</span> <span>
              {tooltip(<Typography>{this.getContent(this.state.startUpPageText.annualRecurringToolTipText,configJSON.annualRecurringToolTipTextArabic)}</Typography>)}
            </span>
          </InputLabel>
          <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText")}>{conditionSort(this.state.language == 'en', 'SAR', "ر.س")}</span>
          <InputBase id="annual-input"
            value={this.getValueFinance(this.state.annualRecurringRevenue)}
            onChange={(e)=>this.getHandleChange(e,'annualRecurringRevenue')}
            data-test-id="annual-input"
            style={conditionSort(this.state.language == 'ar', {
              paddingRight: "40px",
              marginTop: "42px",
              border: this.getBorderColorFinance(this.state.financeFlag,"annualRevenue", this.state.annualRecurringRevenue)
            }, {
              paddingLeft: "40px",
              marginTop: "42px",
              border: this.getBorderColorFinance(this.state.financeFlag,"annualRevenue", this.state.annualRecurringRevenue)
            })}
          />
          {this.state.financeFlag && this.shouldDisplayErrorMessageFinance("annualRevenue", this.state.annualRecurringRevenue)}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="annualgrowth-rate-input">
            {this.getContent(this.state.startUpPageText.annualGrowthRateText,configJSON.annualGrowthRateTextArabic)} <span>{configJSON.startText}</span> <span>
              {tooltip(<Typography>{this.getContent(this.state.startUpPageText.annualGrowthRateToolTipText,configJSON.annualGrowthRateToolTipTextArabic)}</Typography>)}
            </span>
          </InputLabel>
          <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText")} data-test-id="xxxx" style={{fontSize: "18px"}}>%</span>
          <InputBase id="annualgrowth-rate-input" 
           value={this.getValueFinance(this.state.annualGrowthRate)}
           onChange={(e)=>this.getHandleChange(e,'annualGrowthRate')}
            data-test-id="annualgrowth-rate-input"
            style={conditionSort(this.state.language == 'ar', {
              paddingRight: "20px",
              marginTop: "42px",
              border: this.getBorderColorFinance(this.state.financeFlag,"annualGrowthRate", this.state.annualGrowthRate)
            }, {
              paddingLeft: "20px",
              marginTop: "42px",
              border: this.getBorderColorFinance(this.state.financeFlag,"annualGrowthRate", this.state.annualGrowthRate)
            })}
          />
          {this.state.financeFlag && this.shouldDisplayErrorMessageFinance("annualGrowthRate", this.state.annualGrowthRate)}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="lastmonthgrass-input">
            {this.getContent(this.state.startUpPageText.lastMonthGrassRevenueText,configJSON.lastMonthGrossRevenueTextArabic)} <span>{configJSON.startText}</span> <span>
              {tooltip(<Typography>{this.getContent(this.state.startUpPageText.lastMonthGrassRevenueToolTipText,configJSON.lastMonthGrossRevenueToolTipTextArabic)}</Typography>)}
            </span>
          </InputLabel>
          <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText")}>{conditionSort(this.state.language == 'en', 'SAR', "ر.س")}</span>

          <InputBase id="lastmonthgrass-input" required 
           value={this.getValueFinance(this.state.lastMonthGrossRevenue)}
           onChange={(e)=>this.getHandleChange(e,'lastMonthGrossRevenue')}
            data-test-id="lastmonthgrass-input"
            style={conditionSort(this.state.language == 'ar', {
              paddingRight: "40px",
              marginTop: "42px",
              border: this.getBorderColorFinance(this.state.financeFlag,"grassRevenue", this.state.lastMonthGrossRevenue)
            }, {
              paddingLeft: "40px",
              marginTop: "42px",
              border: this.getBorderColorFinance(this.state.financeFlag,"grassRevenue", this.state.lastMonthGrossRevenue)
            })}
          />
          {this.state.financeFlag && this.shouldDisplayErrorMessageFinance("grassRevenue", this.state.lastMonthGrossRevenue)}
        </FormControl>
        <FormControl fullWidth >
          <InputLabel shrink htmlFor="lastmonthgrass-input">
            {this.getContent(this.state.startUpPageText.lastMonthNetprofitText,configJSON.lastMonthNetProfitTextArabic)} <span>{configJSON.startText}</span>
            <span>
              {tooltip(<Typography style={{ whiteSpace: 'pre-line' }}>
                {this.getContent(this.state.startUpPageText.lastMonthNetprofitToolTipTextOne,configJSON.lastMonthNetProfitToolTipTextOneArabic)}
                {this.getContent(this.state.startUpPageText.lastMonthNetprofitToolTipTextTow,configJSON.lastMonthNetProfitToolTipTextTwoArabic)}
                {this.getContent(this.state.startUpPageText.lastMonthNetprofitToolTipTextThree,configJSON.lastMonthNetProfitToolTipTextThreeArabic)}
              </Typography>)}
            </span>
          </InputLabel>
          <span className={conditionSort(this.state.language == 'ar', "staticTextAr", "staticText")}>{conditionSort(this.state.language == 'en', 'SAR', "ر.س")}</span>
          <InputBase id="lastmonthgrass-input" required
           value={this.getValueFinance(this.state.lastMonthNetProfit)}
           onChange={(e)=>this.getHandleChange(e,'lastMonthNetProfit')}
            data-test-id="lastmonthnetprofit-input"
            style={conditionSort(this.state.language == 'ar', {
              paddingRight: "40px",
              marginTop: "42px",
              border:  this.getBorderColorFinance(this.state.financeFlag,"lastMonthNetProfit", this.state.lastMonthNetProfit)
            }, {
              paddingLeft: "40px",
              marginTop: "42px",
              border:  this.getBorderColorFinance(this.state.financeFlag,"lastMonthNetProfit", this.state.lastMonthNetProfit)
            })}
          />
          {this.state.financeFlag && this.shouldDisplayErrorMessageFinance("lastMonthNetProfit", this.state.lastMonthNetProfit)}
        </FormControl>
      </Box>
    )
  }

  getMetricDetails = () => {
    const useStyle = () => {
      return {
        marginTop: "52px",
        width: "100%",
        "& .MuiFormControl-root": {
          marginBottom: "42px",
          "&        .MuiInputLabel-root": this.MuiInputLabelRoot,
          "&      .MuiInputBase-root": this.MuiInputBaseRoot,
          "  &.last": {
            marginBottom: "0",
          },
        },
      };
    };
    return (<>
      <Box sx={useStyle()}>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="numberofusers-input">
            {this.getContent(this.state.startUpPageText.numberOfUsersText,configJSON.numberOfUsersTextArabic)} <span>{configJSON.startText}</span>
          </InputLabel>
          <InputBase id="numberofusers-input"
           value={this.getValueFinance(this.state.numberOfUsers.toString())}
           onChange={(e)=>this.getHandleChange(e,'numberOfUsers')}
            style={{ border : this.state.matricsFlag ? this.getBorderColorMetrics("numberOfUsers", this.state.numberOfUsers):"" }}
            data-test-id="numberofusers"
          />
          {this.state.matricsFlag &&  this.shouldDisplayErrorMessageMetrics("numberOfUsers" ,this.state.numberOfUsers)}
        </FormControl>
      </Box>
    </>)
  }

  renderDisabledButton = () => {
    if (this.state.openStartUpBox || this.state.openBuisnessBox) {
      return false;
    } else {
      return true;
    }
  };
  renderStartUpDotImage = () => {
    if (this.state.openStartUpBox) {
      return <DotImg src={dot} style={{position: "static", width: "16px"}} />;
    }
  };
  renderBuisnessDotImage = () => {
    if (this.state.openBuisnessBox) {
      return <DotImg src={dot} style={{position: "static", width: "16px"}} />;
    }
  };
  isListingModelTrue=()=>{
    return (
      <>
              <Box sx={styles.box18} style={{gap: "36px"}}>
                <Box
                  alignItems={"center"} display={"flex"} 
                  sx={{
                    border: this.state.openStartUpBox
                      ? "4px solid #5412FC"
                      : "2px solid #8C8C8C",
                    ...(!this.state.openStartUpBox
                      ? styles.box19
                      : styles.box20),
                    position: "relative"
                  }}
                  style={{width: "150px", height: "150px"}}
                  className="box19"
                  data-test-id="startUpBoxList"
                  onClick={() => this.handleStartUpBox()}
                >
                  <div style={{position: "absolute", top: "10px", right: "10px"}}>{this.renderStartUpDotImage()}</div>
                  <div
                    style={{
                      width: "100%",
                      height: this.state.openStartUpBox ? "70%" : "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex"
                    }}
                  >
                    <TypographyCategory
                      style={{
                        color: this.state.openStartUpBox
                          ? "rgba(84, 18, 252, 0.80)"
                          : "",
                        fontSize: "22px"
                      }}
                      className="boxText"
                    >
                      {this.state.language === 'ar' ?  configJSON.startUpText_ar : configJSON.startUpText}
                    </TypographyCategory>
                  </div>
                </Box>
                {
                  <Box
                    alignItems={"center"} display={"flex"} 
                    sx={{
                      border: this.state.openBuisnessBox
                        ? "4px solid #5412FC"
                        : "2px solid #8C8C8C",
                      ...(!this.state.openBuisnessBox
                        ? styles.box19
                        : styles.box20),
                        position: "relative"
                    }}
                    style={{width: "150px", height: "150px"}}
                    data-test-id="businessBoxList"
                    onClick={this.handleBuisnessBox}
                  >
                    <div style={{position: "absolute", top: "10px", right: "10px"}}>{this.renderBuisnessDotImage()}</div>
                    <div
                      style={{
                        width: "100%",
                        height: this.state.openBuisnessBox ? "70%" : "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex"
                      }}
                    >
                      <TypographyCategory
                        style={{
                          color: this.state.openBuisnessBox
                            ? "rgba(84, 18, 252, 0.80)"
                            : "",
                            fontSize: "22px"
                        }}
                        className="boxText"
                      >
                        {this.state.language === 'ar' ? configJSON.businessText_ar : configJSON.businessText}
                      </TypographyCategory>
                    </div>
                  </Box>
                }
              </Box>
              {(this.state.openStartUpBox || this.state.openBuisnessBox) && openBox(
                this.state.openStartUpBox,
                this.state.openBuisnessBox,
                this.state.boxTitle,
                this.state.boxContent
              )}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <CustomizeButton
                  data-test-id="nextBtnList"
                  onClick={() => this.createListing()}
                  disabled={this.renderDisabledButton()}
                  style={{
                    width: "171px",
                    height: "48px",
                    background:
                      this.state.openBuisnessBox ||
                        this.state.openStartUpBox
                        ? "#10BFB8"
                        : "#8C8C8C"
                  }}
                  variant="contained"
                >
                  <ButtonTypography style={{...styles.buttonContent, fontSize: "18px"}}>{this.state.language === 'ar' ?configJSON.nextText_ar : configJSON.nextText}</ButtonTypography>
                </CustomizeButton>
              </Box>
            </>
    )
  }

   circularProgressLoader = () => {
      return <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", width: "100%", height: "100%", bgcolor: "#0000003d", zIndex: 1}}>
        <CircularProgress size="30px" />
      </Box>
    }

  renderListingModal = () => {
    return (
      <Container style={styles.container}>
        {this.state.createSMELoader && this.circularProgressLoader()}
        <Box display={"flex"} flexDirection={"column"} style={{...styles.containerBox, margin: 0, gap: "2rem", padding: "24px"}}>
          <Box alignItems={"center"} display={"flex"} justifyContent={"center"}>
            <Top_heading style={{ margin: "0px", fontSize: "22px" }} data-test-id="add-new-listing">
           {this.state.language==="ar"? configJSON.addNewListingText_ar :configJSON.addNewListingText}
            </Top_heading>
            <CancelOutlinedIcon data-test-id="closeListingModal" onClick={() => this.handleListingModal(false)} style={{ position: "absolute", right: "45px", fontSize: "2.2em" }} />
          </Box>
          {!this.state.isListingModalSuccess && this.isListingModelTrue()}
          {this.state.isListingModalSuccess &&
            <>
              <Box style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}>
                <Box style={{
                  borderRadius: "20px",
                  border: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "16px 18px 32px 18px",
                  gap: "12px"
                }}>
                  <div style={{
                    "display": "flex",
                    "gap": "12px",
                    "alignItems": "center",
                    "height": "fit-content",
                  }}>
                    <CheckCircleOutlineOutlinedIcon style={{
                      "fontSize": "2.5em",
                      "color": "#5412FC",
                    }} />
                    <p style={{
                      "fontWeight": 600,
                      "fontSize": "22px",
                    }}>{this.state.language === 'ar' ?configJSON.successText_ar :configJSON.successText}</p>
                  </div>

                  <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
                    <p style={{ fontSize: "18px", textAlign: "center", margin: 0 }}>{this.state.language === 'ar' ?configJSON.listingSuccessFullyAddedText_ar :configJSON.listingSuccessFullyAddedText}</p>
                    <p style={{ fontSize: "18px", textAlign: "center", margin: 0 }}>{this.state.language === 'ar' ?configJSON.youCanAccessText_ar :configJSON.youCanAccessText}</p>
                  </div>

                </Box>

              </Box>
            </>}
        </Box>
      </Container>
    )
  }

  componentDidUpdate(prevProps:any) {
    if (this.state.listId !== this.props.navigation.getParam("id")) {
      this.ClearAll();
      this.componentDidMount();
    }
  }
  async componentDidMount(): Promise<void> {
    this.getListofItems();
    this.getProfileDetails();
    if(this.props.navigation.getParam("id") !== undefined){
    this.setState({listId :this.props.navigation.getParam("id")})
    this.getListings();
    this.getCategoriesRequest();
    this.getCompanyOverViews();
    this.getPrivateInfo();
    this.setState({anchorEl:null})
    this.getListofItems();
    }
  }

  onModalClick = () => { 
      if (this.state.AddListingButton) {
        this.setState({ isListingModal: true });
      }
      else {
        toast.error(this.state.language === 'ar' ?configJSON.addNewListingAfterText_ar:configJSON.addNewListingAfterText,
          {
            position : this.checkIfCondtionForToast( toast.POSITION.TOP_LEFT , toast.POSITION.TOP_RIGHT),
            style : {
              direction :  this.checkIfConditionForDirection()
            }
          
      });

      }
  }

  backgroundColor = (ele:any) => {
    if(ele ==="SME" || ele==="نحن"){
      return "#5412FC"
    }else{
      return "#10BFB8"
    }
  }
  backgroundIcon = (ele:any) => {
    if(ele === "SME" || ele==="نحن"){
       return businessLogo
    }else{
      return rocketIcon
    }    
  }

  onenableAdd = () => {
    return (
      <>
      {this.state.AddListingButton ? "" : <Typography style={{ margin: this.state.language==='ar'?'0 20px 0 0':'0 0 0 20px', fontSize:'12px' }} >{this.state.language === 'ar' ?configJSON.addNewListingAfterText_ar:configJSON.addNewListingAfterText}</Typography>}
      </>
    )
  }
  renderSuccessModal = () => {
    return(<Box display={"flex"} flexDirection={"column"} style={{gap: "22px", padding: "24px"}}>
      <div style={{
        "height": "fit-content",
        "gap": "16px",
        "display": "flex",
        "alignItems": "center",
      }}>
        <CheckCircleOutlineOutlinedIcon style={{
          "fontSize": "3.5em",
          "color": "#5412FC",
        }} />
        <p style={{
          "fontSize": "22px",
          "fontWeight": 600,
          fontFamily: "Montserrat",
          marginTop: "10px",
          marginBottom: "10px"
        }}>{this.state.language === 'ar' ?configJSON.successText_ar :configJSON.successText}</p>
            <CancelOutlinedIcon data-test-id="closeListingModalS" onClick={() => this.handleCloseModal()} style={{ position: "absolute", 
            right: conditionSort(this.state.language == 'ar', "unset", "45px"),
            cursor: "pointer",
            left: conditionSort(this.state.language == 'ar', "45px", "unset"), fontSize: "2.7em" }} />

      </div>
      
      <div style={{display: "flex", flexDirection:"column", gap: "18px"}}>
        <p style={{ fontSize: "18px", fontWeight: 500, fontFamily: "Montserrat", textAlign: conditionSort(this.state.language == 'en', "left", "right"), margin: "0" }}>{this.state.language === 'ar' ?configJSON.successConfirmTextLine1_ar:configJSON.successConfirmTextLine1}</p>
        <p style={{ fontSize: "18px", fontWeight: 500, fontFamily: "Montserrat", textAlign: conditionSort(this.state.language == 'en', "left", "right"), margin: "0" }}>{this.state.language === 'ar' ?configJSON.successConfirmTextLine2_ar:configJSON.successConfirmTextLine2}</p>
        <p style={{ fontSize: "18px", fontWeight: 500, fontFamily: "Montserrat", textAlign: conditionSort(this.state.language == 'en', "left", "right"), margin: "0" }}>{this.state.language === 'ar' ?configJSON.successConfirmTextLine4_ar:configJSON.successConfirmTextLine4} {this.state.hashId}</p>
      </div></Box>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    
    return (
      // Customizable Area Start
      <>
        <Modal
          data-test-id="modal"
          open={this.state.openFileModal}
        >
          <Box style={{
            position: 'absolute',
            width: "80%",
            padding: "5% 2%",
            borderRadius: "12px",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#fff",
            outline: 'none',
            overflowY: 'auto'
          }}
            sx={this.modalStyles()}
          >
            <Grid sm={12} item container>
              <Grid sm={6} item style={{ alignContent: "space-between", justifyContent: "space-between" }}>
                <Typography style={{ fontWeight: 700, fontSize: "24px" }}>{this.state.startUpPageText.uploadDocumentFileText}</Typography>
              </Grid>
              <Grid sm={6} item style={{ justifyContent: "flex-end", textAlign: this.state.language ==="en" ? 'right' : "left" }}>
                <IconButton onClick={() => this.setState({ openFileModal: false })} data-test-id="close-icon">
                  <CancelOutlinedIcon style={{ color: "#000000" }} />
                </IconButton>
              </Grid>
            </Grid>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="title-input">
              {this.state.startUpPageText.titleText}
              </InputLabel>
              <InputBase id="title-input"
                required
                data-test-id="modal-file-title"
                value={this.state.modalFileTitle}
                onChange={(e) => this.setState({ modalFileTitle: e.target.value })}
              />
            </FormControl>
            <input type="file" id="p-input" hidden
              data-test-id="modal-file"
              onChange={this.onFileChange}
            />
            <label htmlFor="p-input" className="upload-documents" style={{ border: this.state.summaryError && !this.state.plStatement ? "1px red dashed" : "" }}>
              <img src={fileUpload} />
              {this.state.modalFile ? this.state.modalFile.name : this.state.startUpPageText.uploadDocumentText}
            </label>
            <div style={{ width: "100%" }}>
              <Button fullWidth id="documents-files-input" data-test-id="modal-file-upload" className="document-file" onClick={this.onFileUpload}>
                <img src={documentUpload} />
              </Button>
            </div>
          </Box>
        </Modal>
            {Boolean(this.state.anchorEl) && (<div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
              }}
            />)}
        <Dialog
          open={this.state.isListingModal}
          onClose={() => this.handleListingModal(false)}
          maxWidth={"lg"}
          data-test-id="modal-add-list"
        >
          {this.state.successStartUpModal ? this.renderSuccessModal() : this.renderListingModal()}
        </Dialog>
        <HeaderBlock />
        <Box
          sx={this.startUpDetailsStyle()}
          data-test-id="main-startup-details"
        >
          
          <div className="drop-down responsive" data-test-id="popover-select" style={{ display: 'flex', alignItems: 'center', background:'#fff',zIndex:1001 }} aria-describedby={Boolean(this.state.anchorEl) ? 'simple-popover' : undefined} onClick={this.handlePopver}>
            <img src={rocketIcon} style={{ backgroundColor: "#10BFB8", borderRadius: "50%", margin: '10px 10px', width: "40px", height: "40px" }} />
            <Typography style={{ marginRight: '20px', width:'70%', fontSize:'20px' }}>{this.state.startupName || this.getContent(this.state.startUpPageText.nameOfTheStartupText,configJSON.startUpTextArabic)}</Typography>
            <img src={selectMenuIcon} style={imageStyles} />
          </div>
          <this.CustomPopOver
            id="simple-popover"
            open={Boolean(this.state.anchorEl)}
            anchorEl={this.state.anchorEl}
            onClose={() => { this.setState({ anchorEl: null }) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
              
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                width: '20%',
                padding: "20px",
                height:'200px'
              },
            }}
            style={{ marginTop: "40px", padding: '10px 20px', marginLeft:'30px' }}
          >
            <div className="drop-down"  data-test-id="popover-mod" style={{ justifyContent: "center", height: '100px', background:'#fff' }} >
            {this.state.list && this.state.list.map((ele, index) => (
             
             <div data-test-id="rediret-url" onClick={() => this.redirectToUrl(ele.id, ele.attributes.category_id)} className="drop-down" key={index} style={{ display: 'flex', alignItems: 'center', borderBottom: "1px solid #000", padding: '20px 0', cursor:"pointer" }} >
             <img src={this.backgroundIcon(ele.attributes.category_name)} style={{ backgroundColor: this.backgroundColor(ele.attributes.category_name), borderRadius: "50%", width: "30px", height: "30px" }} alt={ele.name} />
           <Typography style={{margin: this.state.language==='ar'?'0 20px 0 0':'0 0 0 20px', fontSize:'20px' }} >{ele.attributes.name || ele.attributes.category_name}</Typography>
             </div>
            
            ))}
              <div className="drop-down" style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}
                onClick={this.onModalClick}
                data-test-id="openlisting-model" 
                >
                <AddCircleOutlineIcon />
                <div>
                <Typography style={{ margin: this.state.language==='ar'?'0 20px 0 0':'0 0 0 20px', fontSize:'18px' }} >  
           {this.state.language==="ar"? configJSON.addNewListingText_ar :configJSON.addNewListingText}
                </Typography>
                {this.onenableAdd()}
                </div>
              </div>
            </div>
          </this.CustomPopOver>
          
          {conditionSort(this.state.loading, <Box width={"100%"} height={"300px"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                      <CircularProgress />
                  </Box>, <><CardDetails data-test-id="card-details" profilePercent={this.state.profile_percent}  language={this.state.language} progress={this.state.progress} listingStatus={this.state.listingStatus} nameoftheListing={this.state.startupName? this.state.startupName: this.getContent(configJSON.startUpText, configJSON.startUpTextArabic) as string}
            pageText={
              this.getContent(this.state.startUpPageText,
                {...this.state.startUpPageText, 
                  myListingText: configJSON.myListingTextArabic,
                  needHelpText: configJSON.needHelpTextArabic,
                  exploreListingText: configJSON.exploreListingTextArabic,
                  completeYourProfileFullText: configJSON.completeYourProfileFullTextArabic,
                  completeYourProfileText: configJSON.completeYourProfileTextArabic,
                  CompleteText: configJSON.completeTextArabic}) as IStartUpPageText
            }
          />
          <AccordionComponent
            heading={this.getContent(this.state.startUpPageText.PrivateInformationHeadingText,configJSON.privateInfoHeadingTextArabic) as string}
            subHeading={this.getContent(this.state.startUpPageText.privateSubHeading, configJSON.privateSubHeadingArabic) as string}
            data-test-id="accordion-component-private"
            onSave={this.onPrivateInformationSave}
            onClear={this.privateInformationClear}
            buttonDisable = {this.privateInformationDisable()}
            saveText={this.getContent(this.state.startUpPageText.saveText,configJSON.saveTextArabic) as string}
            clearText={this.getContent(this.state.startUpPageText.clearText,configJSON.clearTextArabic) as string}
            expand = {this.state.expand}
            handleAccordClick={this.handleAccordClick}
            keyValue="personalInfo"
          >
            {this.getPrivateInformation()}
          </AccordionComponent>
          <AccordionComponent
            heading={this.getContent(this.state.startUpPageText.summaryText,configJSON.summaryTextArabic) as string}
            subHeading={this.getContent(this.state.startUpPageText.subheadingForAllText, configJSON.subheadingForAllTextArabic) as string}
            data-test-id="accordion-component-summary"
            onSave={this.onSummarySave}
            onClear={this.onSummaryClear}
            buttonDisable = {this.summaryButtonDisable()}
            saveText={this.getContent(this.state.startUpPageText.saveText,configJSON.saveTextArabic) as string}
            clearText={this.getContent(this.state.startUpPageText.clearText,configJSON.clearTextArabic) as string}
            expand = {this.state.expand}
            handleAccordClick={this.handleAccordClick}
            keyValue="summarybtn"
          >
            {this.getSummary()}
          </AccordionComponent>
          <AccordionComponent
            heading={this.getContent(this.state.startUpPageText.companyOverViewText,configJSON.companyOverViewTextArabic) as string}
            subHeading={this.getContent(this.state.startUpPageText.subheadingForAllText, configJSON.subheadingForAllTextArabic) as string}
            data-test-id="accordion-component-company"
            onSave={this.onCompanyOverViewSave}
            onClear={this.onCompanyOverViewClear}
            buttonDisable={this.companyButtonDisable()}
            saveText={this.getContent(this.state.startUpPageText.saveText,configJSON.saveTextArabic) as string}            
            clearText={this.getContent(this.state.startUpPageText.clearText,configJSON.clearTextArabic) as string}
            expand = {this.state.expand}
            handleAccordClick={this.handleAccordClick}
            keyValue="companybtn"
          >
            {this.getCompanyOverView()}
          </AccordionComponent>
          <AccordionComponent
            heading={this.getContent(this.state.startUpPageText.AcquisitionText,configJSON.acquisitionTextArabic) as string}
            subHeading={this.getContent(this.state.startUpPageText.subheadingForAllText, configJSON.subheadingForAllTextArabic) as string}
            data-test-id="accordion-component-aquisition"
            onSave={this.onAcquisitionDetailsSave}
            onClear={this.onAqcuisitionDetailsClear}
            buttonDisable={this.onAqcuisationButtonDisable()}
            saveText={this.getContent(this.state.startUpPageText.saveText,configJSON.saveTextArabic) as string}
            clearText={this.getContent(this.state.startUpPageText.clearText,configJSON.clearTextArabic) as string}
            expand = {this.state.expand}
            handleAccordClick={this.handleAccordClick}
            keyValue="aqcuisationbtn"
          >
            {this.getAquisitionDetails()}
          </AccordionComponent>
          <AccordionComponent
            heading={this.getContent(this.state.startUpPageText.financialHeadingText,configJSON.financesHeadingArabic) as string}
            subHeading={this.getContent(this.state.startUpPageText.subheadingForAllText, configJSON.subheadingForAllTextArabic) as string}
            data-test-id="accordion-component-financial"
            onSave={this.onFinancialSave}
            onClear={this.onFinancialClear}
            buttonDisable={this.onFinancialButtonDisable()}
            saveText={this.getContent(this.state.startUpPageText.saveText,configJSON.saveTextArabic) as string}
            clearText={this.getContent(this.state.startUpPageText.clearText,configJSON.clearTextArabic) as string}
            expand = {this.state.expand}
            handleAccordClick={this.handleAccordClick}
            keyValue="Financialbtn"
          >
            {this.getFinancialDetails()}
          </AccordionComponent>
          <AccordionComponent
            heading={this.getContent(this.state.startUpPageText.customerMetricsHeadingText, configJSON.customerAndVerdorTextArabic) as string}
            subHeading={this.getContent(this.state.startUpPageText.subheadingForAllText, configJSON.subheadingForAllTextArabic) as string}
            data-test-id="accordion-component-customerMetrics"
            onSave={this.onMetricSave}
            onClear={this.onCancelMetric}
            buttonDisable={!this.state.numberOfUsers}
            saveText={this.getContent(this.state.startUpPageText.saveText,configJSON.saveTextArabic) as string}
            clearText={this.getContent(this.state.startUpPageText.clearText,configJSON.clearTextArabic) as string}
            expand = {this.state.expand}
            handleAccordClick={this.handleAccordClick}
            keyValue="Metricbtn"
          >
            {this.getMetricDetails()}
          </AccordionComponent></>)}
          
        </Box>
        <FooterBlock />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export enum CatalogueType {
  BUSINESS = "business",
  STARTUP = "start up",
}

const getTypeInfo = (type: CatalogueType) => {
  const typeImg =
    type.toLowerCase() === CatalogueType.BUSINESS ? businessLogo : rocketIcon;
  const typeColor =
    type.toLowerCase() === CatalogueType.BUSINESS ? "#5412FC" : "#10BFB8";
  const typeColorOpacity =
    type.toLowerCase() === CatalogueType.BUSINESS
      ? "rgba(84, 18, 252, 0.5)"
      : "rgba(16, 191, 184, 0.5)";
  return { typeImg, typeColor, typeColorOpacity };
};

interface CardDetailsProps {
  progress:string;
  listingStatus: string;
  nameoftheListing:string;
  language: string;
  profilePercent: string
  pageText : {
    CompleteText: string;
    myListingText: string;
    completeYourProfileFullText: string;
    needHelpText: string;
    completeYourProfileText:string;
    exploreListingText: string;
  }
}

export const CardDetails : React.FC<CardDetailsProps> = ({ progress , nameoftheListing,pageText, listingStatus, language, profilePercent
}) => {
  const { typeColor, typeColorOpacity } = getTypeInfo(CatalogueType.STARTUP);
  const cardDetailsStyle = () => {
    return {
      border: "1px #262626 solid",
      borderRadius: "20px",
      marginBottom: "24px",
      "& .progress-bar-box": {
        height: "20px",
        background: typeColorOpacity,
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        overflow: "hidden",
        "& .progress-bar": {
          width: `${progress}%`,
          height: "100%",
          background: typeColor,
          borderBottomRightRadius: "20px",
          borderTopRightRadius: "20px",
        },
      },
      "& .progress-complete": {
        color: "black",
        fontSize: "20px",
        fontFamily: "Montserrat",
        fontWeight: 400,
        lineHeight: "24px",
        wordWrap: "break-word",
        margin: "15px 22px 0",
        display: "flex",
        justifyContent: "flex-end",
      },
      "& .details-section": {
        margin: "0 42px 28px 60px",
        "& .heading-section": {
          display: "flex",
          gap: "40px",
          alignItems: "flex-start",
          marginBottom: "40px",
          "& img": {
            height: "64px",
            width: "64px",
          },
          "& .heading": {
            display: "grid",
            justifyItems: "start",
            gap: "4px",
            "& .sub-heading": {
              color: "black",
              fontSize: "14px",
              fontFamily: "Montserrat",
              fontWeight: 400,
              lineHeight: "24px",
              wordWrap: "break-word",
            },
            "& .heading-text": {
              display: 'flex',
              alignItems: 'center',
              color: "black",
              fontSize: "30px",
              fontFamily: "Montserrat",
              fontWeight: 700,
              wordWrap: "break-word",
            },
          },
        },
        "& .text-section": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          marginBottom: "24px",
          "& .text": {
            color: "black",
            fontSize: "20px",
            fontFamily: "Montserrat",
            fontWeight: 400,
            lineHeight: "24px",
            wordWrap: "break-word",
            maxWidth: "50%",
          },
        },
        "& .button-section": {
          display: "flex",
          justifyContent: "space-between",
          "& .complete-button": {
            background: "#10BFB8",
            borderRadius: "12px",
            color: "white",
            fontSize: "20px",
            fontFamily: "Montserrat",
            fontWeight: 700,
            lineHeight: "24px",
            wordWrap: "break-word",
            padding: "12px 15px",
            border: "none",
            cursor: "pointer",
            "&:hover": {
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
          "& .exploreLink": {
            textDecoration: "none !important",
            cursor: "pointer"
          },
          "& .explore-button": {
            border: "2px #8C8C8C solid",
            borderRadius: "12px",
            color: "#8C8C8C",
            fontSize: "20px",
            fontFamily: "Montserrat",
            fontWeight: 700,
            lineHeight: "24px",
            wordWrap: "break-word",
            padding: "12px 36px",
            background: "transparent",
          },
        },
      },
      "@media (max-width:600px)": {
        marginBottom: "15px",
        "& .progress-complete": {
          fontSize: "12px",
          margin: "9px 20px 0",
        },
        "& .details-section": {
          margin: "0 24px 50px 25px",
          "& .heading-section": {
            gap: "17px !important",
            marginBottom: "63px !important",
            "& img": {
              height: "54px !important",
              width: "54px !important",
            },
            "& .heading": {
              gap: "4px !important",
              "& .sub-heading": {
                fontSize: "12px !important",
              },
              "& .heading-text": {
                fontSize: "14px !important",
              },
            },
          },
          "& .text-section": {
            marginBottom: "18px !important",
            "& .text": {
              fontSize: "10px !important",
              lineHeight: "16px !important",
            },
          },
          "& .button-section": {
            "& .complete-button": {
              borderRadius: "5px !important",
              fontSize: "10px !important",
              lineHeight: "13px !important",
              padding: "7px !important",
            },
            "& .explore-button": {
              borderRadius: "5px !important",
              fontSize: "10px !important",
              padding: "7px 19px !important",
              lineHeight: "13px !important",
            },
          },
        },
      },
    };
  };

  const handleCloseBuyer = () => {
    let existRecord = window.document.getElementById("anchorID") as HTMLAnchorElement;
    existRecord.href = "/Catalogue";
  };

  return (
    <>
      <Box sx={cardDetailsStyle()} data-test-id="card">
        <div className="progress-bar-box">
          <div className="progress-bar" />
        </div>
        <div className="progress-complete">{progress || 0}% {pageText.CompleteText}</div>
        <div className="details-section">
          <div className="heading-section">
            <img src={rocketIcon} style={{ backgroundColor: "#10BFB8", borderRadius: "50%" }} />
            <div className="heading">
              <div className="sub-heading">{pageText.myListingText}</div>
              <div className="heading-text">{nameoftheListing}
                {Number(progress).toFixed(1) == "100.0" && <span style={{
                  textTransform: "capitalize", color: "white",
                  marginLeft: "20px",
                  background: conditionSort(
                    language === 'ar',
                    configJSON.listingStatusColors[configJSON.listingStatusArabic[listingStatus]],
                    configJSON.listingStatusColors[configJSON.listingStatusEn[listingStatus]],
                  ) || "#8C8C8C",
                  borderRadius: "20px", padding: "5px 20px", fontSize: "18px"
                }}> {
                    conditionSort(
                      language === 'ar',
                      configJSON.listingStatusArabic[listingStatus],
                      configJSON.listingStatusEn[listingStatus]
                    ) || "تحت المراجعة"
                  }</span>}</div>
            </div>
          </div>
          <div className="text-section">
            <div className="text">
            {
              conditionSort(Number(profilePercent) != 100, pageText.completeYourProfileFullText, null)
            }
            </div>
            <div className="text">{pageText.needHelpText}</div>
          </div>
          <div className="button-section">
            {
              conditionSort(Number(profilePercent) != 100, <Link to="/sellerProfile" style={{textDecoration:'none'}}className="complete-button">{pageText.completeYourProfileText}</Link>, <div></div>)
            }
            
            <NavTag id="anchorID" onClick={handleCloseBuyer} className="exploreLink" data-test-id = "catalogueNavTestId">
              <button className="explore-button" data-test-id = "catalogueNavTextTestId">{pageText.exploreListingText}</button>
            </NavTag>
          </div>
        </div>
      </Box>
    </>
  );
};

interface AccordionComponentProps {
  heading: string;
  subHeading: string;
  removeActions?: boolean;
  onSave: () => void;
  onClear?: () => void;
  buttonDisable? : boolean;
  saveText? : string;
  clearText? :string;
  expand?:string;
  handleAccordClick: (key: string) => void;
  keyValue: string
}

export const AccordionComponent: React.FC<AccordionComponentProps> = ({
  heading,
  subHeading,
  children,
  removeActions = false,
  onSave,
  onClear,
  buttonDisable,
  saveText,
  clearText,
  expand,
  handleAccordClick,
  keyValue,
  }) => { const headerRef = useRef<HTMLDivElement>(null);
    const handleSaveClick = () => {
    if (headerRef.current) {
    headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });}
    onSave();};

  const AccordionComponentStyle = () => {
    return {
      border: "1px #262626 solid",
      borderRadius: "20px",
      overflow: "hidden",
      padding: "32px 40px",
      marginBottom: "24px",
      "& .MuiAccordion-root": {
        borderRadius: "0 !important",
        boxShadow: "none",
        "& .MuiAccordionSummary-root": {
          padding: "0",
          minHeight: "auto",
          "& .MuiIconButton-root": {
            margin: "0 !important",
            padding: "0 !important",
            "& .MuiIconButton-label": {
              cursor: "pointer",
              height: "48px",
              width: "48px",
              "& img": {
                rotate: "180deg"
                },
            },
          },
          "& .MuiAccordionSummary-content": {
            display: "grid",
            margin: "0",
            gap: "16px",
            "& .heading": {
              color: "#262626",
              fontSize: "30px",
              fontFamily: "Montserrat",
              fontWeight: 700,
              lineHeight: "24px",
              wordWrap: "break-word",
            },
            "& .sub-heading": {
              fontSize: "16px",
              color: "#8C8C8C",
              fontFamily: "Montserrat",
              lineHeight: "24px",
              fontWeight: 400,
              wordWrap: "break-word",
            },
          },
        },
        "& .MuiCollapse-wrapperInner": {
          marginTop: "24px",
          "& .MuiDivider-root": {
            backgroundColor: "rgba(0, 0, 0, 1)",
          },
          "& .MuiAccordionDetails-root": {
            padding: "0",
          },
          "& .MuiAccordionActions-root": {
            display: "flex",
            padding: "0",
            alignItems: "center",
            justifyContent: "center",
            gap: "200px",
            margin: "40px 0 0",
            "& .clear-button": {
              borderRadius: "12px",
              border: "2px #8C8C8C solid",
              color: "#8C8C8C",
              fontSize: "20px",
              fontFamily: "Montserrat",
              fontWeight: 700,
              lineHeight: "34px",
              wordWrap: "break-word",
              padding: "7px 73px",
              background: "transparent",
              cursor: "pointer",
              "&:hover": {
                boxShadow:
                  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              },
            },
            "& .save-button": {
              border: "none",
              borderRadius: "12px",
              fontSize: "20px",
              color: "white",
              fontFamily: "Montserrat",
              lineHeight: "34px",
              fontWeight: 700,
              wordWrap: "break-word",
              background: "#8C8C8C",
              padding: "7px 75px",
              cursor: "arrow",
              "&:hover": {
                boxShadow:
                  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              },
            },
          },
        },
      },
      "@media (max-width:759px)": {
        "& .MuiAccordion-root": {
          "& .MuiCollapse-wrapperInner": {
            "& .MuiAccordionActions-root": {
              gap: "100px !important",
            },
          },
        },
      },
      "@media (max-width:600px)": {
        marginBottom: "15px",
        padding: "48px 32px 34px",
        "& .MuiAccordion-root": {
          "& .MuiAccordionSummary-root": {
            "& .MuiIconButton-root": {
              "& .MuiIconButton-label": {
                height: "19px !important",
                width: "19px !important",
                "& img": {
                  height: "7px !important",
                  width: "14px !important",
                },
              },
            },
            "& .MuiAccordionSummary-content": {
              gap: "6px !important",
              "& .heading": {
                lineHeight: "24px !important",
                fontSize: "20px !important",
              },
              "& .sub-heading": {
                lineHeight: "15px !important",
                fontSize: "10px !important",
              },
            },
          },
          "& .MuiCollapse-wrapperInner": {
            marginTop: "15px !important",
            "& .MuiAccordionActions-root": {
              gap: "58px !important",
              margin: "40px 0 22px !important",
              "& .clear-button": {
                borderRadius: "5px !important",
                fontSize: "10px !important",
                padding: "6px 24px !important",
                lineHeight: "10px !important",
              },
              "& .save-button": {
                borderRadius: "5px !important",
                fontSize: "10px !important",
                padding: "8px 28px !important",
                lineHeight: "10px !important",
              },
            },
          },
        },
      },
    };
  };
  
  return (
    <>
      <Box sx={AccordionComponentStyle()} data-test-id="accordion">
        <Accordion 
        expanded={expand === keyValue}
        onChange={()=> handleAccordClick(keyValue)}>
          <AccordionSummary
            expandIcon={<img src={ExpandIcon} />}
            aria-controls="panel1c-content"
            id="panel1c-header" 
            ref={headerRef}
          >
            <div className="heading">{heading}</div>
            <div className="sub-heading">{subHeading}</div>
          </AccordionSummary>
          <Divider color="#000000" />
          <AccordionDetails>{children}</AccordionDetails>
          {!removeActions && (<AccordionActions>
            <button className="clear-button" onClick={onClear} data-test-id="clear">{clearText}</button>
            <button className="save-button" style={{background : !buttonDisable ? "#10BFB8": "", cursor: !buttonDisable ? 'pointer' : "arrow"}} onClick={handleSaveClick} data-test-id="save">{saveText}</button>
          </AccordionActions>)}
        </Accordion>
      </Box>
    </>
  );
};

interface SelectMenuProps {
  selectedValue?: string;
  menuList: { icon?: string; value: string; title: string }[];
  disabled?: boolean;
  error?: boolean;
  onOptionClick?: (value: string) => void;
}

export const SelectMenu: React.FC<SelectMenuProps> = ({
  selectedValue,
  menuList,
  disabled,
  error,
  onOptionClick
}) => {
  const [close, setClose] = useState(false);
  const SelectMenuStyle = () => {
    return {
      border: "1px #8C8C8C solid",
      borderRadius: "12px",
      marginTop: "30px",
      overflow: "hidden",
      "& .MuiAccordion-root": {
        boxShadow: "none",
        borderRadius: "0 !important",
        "& .MuiAccordionSummary-root": {
          minHeight: "auto",
          padding: "0",
          "& .MuiIconButton-root": {
            padding: "0 !important",
            margin: "0 !important",
            "& .MuiIconButton-label": {
              width: "28px",
              height: "28px",
              padding: "10px 20px",
            },
          },
          "& .MuiAccordionSummary-content": {
            "& .heading": {
              padding: "12px 24px",
              color: "black",
              fontSize: "20px",
              fontFamily: "Montserrat",
              fontWeight: 400,
              wordWrap: "break-word",
            },
          },
        },
        "& .MuiCollapse-wrapperInner": {
          margin: "0px 20px 20px",
          "& .MuiDivider-root": {
            backgroundColor: "#8C8C8C",
          },
          "& .MuiAccordionDetails-root": {
            padding: "8px 0 0",
            display: "block",
            "& .option": {
              padding: "8px",
              color: "black",
              fontSize: "16px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: "24px",
              wordwrap: "break-word",
              "&:hover": {
                background: "rgb(140, 140, 140, 0.5)",
                borderRadius: "20px",
              },
            },
          },
        },
      },
      "& .MuiAccordion-root.Mui-disabled": {
        backgroundColor: "transparent",
      },
    };
  };
  const handleClick = (value: string) => {
    if (onOptionClick) onOptionClick(value);
  }
  const handleClose = () => {
    setClose(!close)
  }
  const foundObject = menuList.find(obj => obj.value === selectedValue)
  return (
    <>
      <Box sx={SelectMenuStyle()} data-test-id="accordion" style={{ border: error && !selectedValue ? "1px red solid" : "" }}>
        <Accordion disabled={disabled} expanded={close} onClick={handleClose} data-test-id="accordian-close">
          <AccordionSummary
            expandIcon={<img src={selectMenuIcon} />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div className="heading">{foundObject?.title}</div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            {menuList.map((menu,index) => (
              <div
                className="option"
                data-test-id={`menulist-${index}`}
                onClick={() => { handleClick(menu.value) }}
              >{menu.icon ? <img style={{height: "24px", width:"24px", marginRight: "24px", marginLeft:"24px"}} src={menu?.icon} alt={menu.title}/>: <></>}
                {menu.title}
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

interface MultiMenuProps {
  options: {title:string,value:string}[];
  selected: string[];
  onCheck: (value: string[]) => void;
  error: boolean;
  otherChecked: boolean;
  inputValue: string;
  onOtherCheckUpdate: (value: boolean) => void;
  valueUpdate: (Value: string) => void;
  otherText: string;
  language: string;
}
export const MultiCheckBox: React.FC<MultiMenuProps> = ({ options,
  selected, onCheck, error, otherChecked, inputValue,otherText, onOtherCheckUpdate, valueUpdate, language
}) => {

  const errorField = {
    fontFamily: "Montserrat",
    color: "#FF7575",
    marginTop: "0px",
    fontSize: "14px",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "24px",
    padding: "4px 15px",
    marginBottom: "0px",
    "@media (max-width:600px)": {
      marginTop: "4px",
      padding: "0"
    },
  }
  
  const style = () => {
    return {
      width: "100%",
      "& .check-box-div": {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "40px",
        marginBottom: "20px"
      },
      "& .check-box-wrap": {
        flexBasis: "33.33%",
        maxWidth: "500px",
        "@media (max-width:600px)": {
          flexBasis: "100%",
        },
      },
    };
  };
  const handleClick = (checked: boolean, value: string) => {
    if (checked) {
      const updatedArray = [...selected, value];
      if (onCheck) onCheck(updatedArray);
    } else {
      const index = selected.indexOf(value);
      if (index !== -1) {
        const updatedArray = [...selected.slice(0, index), ...selected.slice(index + 1)];
        if (onCheck) onCheck(updatedArray);
      }
    }

  }
  return (
    <Box sx={style()}>
      <div style={{ display: "flex", justifyItems: "center" }} className="check-box-div">
        {options.map((option, index) => (
          <div className="check-box-wrap" key={index}>
            <Checkbox
              data-test-id={`checkbox-${index}`}
              checked={selected.includes(option.value)}
              onChange={(e) => handleClick(e.target.checked, option.value)}
              style={{ color: (error && !selected.length && !otherChecked) ? 'red' : "" }}
            />
            <Typography style={{ display: 'inline' }}>{option.title}</Typography>
          </div>
        ))}
        <Grid justifyContent="center" className="check-box-wrap">

          <Checkbox data-test-id={`checkbox-other`} checked={otherChecked} onChange={(e) => { onOtherCheckUpdate(e.target.checked) }} />
          <Typography style={{ display: 'inline' }}>{otherText}</Typography>
          <input
            style={{
              borderBottom: "1px solid #000000",
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              outline: "none",
              width: "100px"
            }} className="input"
            disabled={!otherChecked}
            value={inputValue}
            onChange={(e) => valueUpdate(e.target.value)}
            data-test-id="input-value-other"
          />
        {conditionSort(error && otherChecked && !inputValue, <Box sx={errorField}>{conditionSort(language == 'en', 'This field is required', `هذا الحقل مطلوب`)}</Box>, null)}

        </Grid>
      </div>
    </Box>
  )
}

const imageStyles = { width: '30px', height: '40px', paddingRight:'5px',margin: "0 1rem" }
// Customizable Area End
