import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { getStorageData, setStorageData, removeStorageData} from "../../../framework/src/Utilities";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export type Props =  WithStyles & {
  navigation: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  descripAr:any[],
  titleAr:string,
  descrip:any[],
  title:string,
  termsConditionsText:{
    saveButton :string;
    cancelButton:string
  }
  languageState:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class TermsConditionsController extends BlockComponent<Props,S,SS> {
  // Customizable Area Start
  TermsconditionsAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      descripAr:[],
      titleAr:"",
      descrip:[],
      title:"",
      languageState:"ar",
      termsConditionsText :{
        saveButton :configJSON.btnTxtAccept,
        cancelButton:  configJSON.btnTxtDecline
      }
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getTermsConditionsApiCall();
    this.getLanguageText();
    // Customizable Area End
  }

  

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.messageResponseApi(message);
    // Customizable Area End
  }

  // Customizable Area Start

  setTerms = async (termsType: boolean) => {
    let registerData = JSON.parse(await getStorageData("toBeRegister"));
    registerData.checkedFinal = termsType;
    removeStorageData("toBeRegister")
    setStorageData("toBeRegister", JSON.stringify(registerData));
  }

  handleDeclineTermsCondition = async() => {
    this.setTerms(false);
    this.props.navigation.navigate("EmailAccountRegBlock");
  }

  handleAcceptTermsCondition = async() => {
    this.setTerms(true);
    this.props.navigation.navigate("EmailAccountRegBlock");
  }
  

  messageResponseApi = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(this.TermsconditionsAPICallId === apiRequestCallId){
      if(responseJson && responseJson.data){
        
        const attributestit = responseJson.data.map((data : any) =>  data.attributes.title)
        const attributesdes = responseJson.data.map((data : any) =>  data.attributes.description)
        const attributestitAr = responseJson.data.map((data : any) =>  data.attributes.arabic_title)
        const attributesdesAr = responseJson.data.map((data : any) =>  data.attributes.arabic_description)
        this.setState({
          descripAr:attributesdesAr,
          titleAr:attributestitAr[0],
          descrip: attributesdes,
          title: attributestit[0],
        })
      }
    }
  } 

  getTermsConditionsApiCall = async () => {
    const headers = {
      "content-type": "application/json",
      "locale":"ar"
  };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getTermsSignup);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), `${configJSON.getApiMethod}`);
    this.TermsconditionsAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  getLanguageText = async () => {
    let lang = await getStorageData('language')
    if (lang) {
      return   this.setState({ languageState: lang },  () => this.applyTranslation())
    }
    this.setState({ languageState: 'en' })
  }

  applyTranslation = async () => {
    let translatedObj : any = await TranslateText(this.state.termsConditionsText, this.state.languageState, 'en')
      this.setState({ termsConditionsText: translatedObj });
  }
  // Customizable Area End
}
